body {
  color: #44577a !important;
  background-color: #f8f8f8 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}

/* a{
    color:#44577a!important;
    text-decoration: underline!important;
} */
a:hover,
a:focus {
  text-decoration: none !important;
}

.backColor {
  background: #feece7;
  padding: 2px;
}

.InActiveJobStataus {
  color: red;
}

.QuillEditor .ql-editor {
  height: 17rem  !important;
  min-height: 13rem !important;
}


.backbtns .MuiIconButton-root {
  padding: 0px !important;
}

.ActiveJobStataus {
  color: green;
}

.tabBox {
  background: #f6f6f6 !important;
}

.activetab1 {
  background-color: #001892;
  color: #fff !important;
  padding: 8px;
  border-radius: 5px;
}

.mainNav1 {
  border: 2px solid #5d45f8;
  border-radius: 5px;
}

.font-colo {
  color: #000000 !important;
}

.logoLogin {
  margin-bottom: 30px;
}

#overlay header {
  width: fit-content;
}

.css-v9efdy {
  margin: 32px;
  width: unset !important;
}

.css-gecs57 {
  overflow: unset !important;
}

.placeholder:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.centeredText {
  text-align: center;
  top: 60%;
  position: absolute;
  left: 8%;
}

.galleryClass div {
  margin-top: unset !important;
}

.gallerySecondClass {
  padding-top: unset !important;
}

.gallerySecondClass {
  margin-top: 70px !important;
}

.row-icon {
  text-align: left !important;
}

.ginormous img {
  width: 160px !important;
}

/* .tk1{
    position: relative;
} */

/* .css-1sv4srx-MuiButtonBase-root-MuiListItemButton-root{
    color: #ffffff !important;
}
.mainTitle .MuiListItemText-root {
    color: #849f93 !important;
} */
.loginPanel label {
  top: -4px;
}

.talentChild {
  box-shadow: none !important;
  margin: 0 0 2px 17px !important;
  border-radius: 9px !important;
  width: 170px;
  cursor: pointer;
}

.talentChild .talentChildCaption {
  margin: 6px 10px;
}

.talentTreeBox {
  padding: 15px 0 !important;
}

.album-contain {
  width: fit-content;
}

.album {
  position: relative;
  border: 1px solid transparent;
  height: 127px;
  overflow: hidden;
  margin-top: -12px;
  border-radius: 7px;
}

.album.ginormous img {
  width: 160px !important;
  margin-top: -18px;
}

.album .centeredText {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 1;
}

.border-cards {
  border-style: dashed;
  border-width: 1px;
  width: 200px;
  border-radius: 8px;
}

.border-cards-second {
  width: 200px;
  height: 303px;
}

.border-cards-photos {
  width: 376px;
  /* height: 135px !important; */
  height: 95px !important;
  border-radius: 8px !important;
}

.cards-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.border-cards-video {
  width: 264px;
  height: 180px !important;
  border-radius: 8px !important;
}

.border-cards-profile {
  width: 200px;
  height: 200px !important;
  border-radius: 8px !important;
}

.disp-input {
  display: flex;
  gap: 8px;
}

.sub-cards-proj {
  height: 230px;
}

.set-proj-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-left: 16px;
}

.Grid {
  width: 75%;
}

.Grid .albName {
  display: none;
}

.Grid img {
  max-height: 150px;
  max-width: 100%;
}

.tabs-class .MuiTabs-flexContainer {
  justify-content: space-between !important;
  border-style: solid !important;
  border-color: #001892 !important;
  border-width: 2px !important;
  border-radius: 8px;
}

.tabs-class .Mui-selected {
  background-color: #001892 !important;
  color: #f8f8f8 !important;
  border-radius: 9px !important;
  width: 166px !important;
}

.tabs-class .MuiTab-root {
  width: 166px !important;
  margin-right: 0px !important;
  margin: 1px !important;
}

.tabs-class span {
  display: none !important;
}

.Box {
  flex-wrap: nowrap;
  display: block;
  width: 25%;
}

.Box .albName {
  width: 94%;
  margin: 10px 3% 0;
  text-align: left;
}

.album .albumName {
  position: absolute;
  left: 34px;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 0;
  text-align: left;
  color: #fff;
  font-weight: 500;
  text-shadow: 1px 1px 2px #000;
  line-height: 1.3;
}

.icon-award {
  position: absolute;
  top: 95px;
  right: 11px;
}

.icon-award1 {
  position: absolute;
  top: 8px;
  right: 5px;
}

.sub-cards-photos {
  height: 230px;
}

.set-proj-cards-up .css-1eamox7 {
  padding: 0px !important;
}

.width-icon {
  min-width: unset !important;
}

.HowerButton:hover {
  text-decoration: none;
  background-color: none !important;
  border: 1px solid #001892;
}

.css-1v2nedh-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #001892 !important;
}

.css-iqpijo-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #001892 !important;
}

@media (min-width: 1200px) {
  .AudioWidth {
    max-width: unset !important;
  }
}

/* .css-1f2yong-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root{
    background-color: #001892 !important;
} */
/* .css-fqqnlk-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover{
    background-color: #001892 !important;
}
.css-144uuw7-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #001892 !important;
}
.css-ixyuf3-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root{
    background-color: #001892 !important;
} */
.palleteBox .MuiTreeItem-content {
  background: none !important;
}

.loginImage {
  width: 300px !important;
  position: relative !important;
  background-color: #000;
  background-size: contain;
  object-fit: inherit;
  margin: 0 auto;
  top: 50px;
}

.loginBlock {
  width: 100%;
  position: relative;
  z-index: 99;
}

.loginBlock .MuiOutlinedInput-input {
  height: 30px;
  padding: 8px 15px;
}

.btnCustom {
  background: #001892 !important;
  box-shadow: none !important;
  min-width: 150px;
}

.btnCustom:hover,
.btnCustom:focus {
  background: #44577a !important;
  color: #fff !important;
}

.css-ct8fc-MuiTreeItem-content.Mui-selected {
  background: none !important;
}

.palleteBoxCaption {
  border-radius: 10px !important;
  margin: 3px !important;
}

/* .css-gjzmv4-MuiPaper-root-MuiCard-root{
    border-radius: 10px !important;
    margin: 3px !important;
} */
.css-o4b71y-MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.css-90h10p-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background-color: #ffffff !important;
}

.or {
  background: #001892;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  padding: 8px;
  text-align: center;
  margin: 0 auto;
}

.socialMedia {
  margin: 15px -25px;
  text-align: center;
}

.socialMedia a {
  width: 28%;
  display: inline-block;
  margin: 6px 2%;
  box-shadow: none;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 16px;
  padding: 10px;
  font-weight: 500;
}

.socialMedia svg {
  vertical-align: middle;
}

.socialMedia a:hover {
  opacity: 0.9;
}

.socialMedia a.google {
  background: #dd4b39;
}

.socialMedia a.facebook {
  background: #3b5999;
}

.socialMedia a.instagram {
  background: rgb(217, 158, 77);
  background: -moz-linear-gradient(
    90deg,
    rgba(217, 158, 77, 1) 0%,
    rgba(128, 40, 222, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(217, 158, 77, 1) 0%,
    rgba(128, 40, 222, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(217, 158, 77, 1) 0%,
    rgba(128, 40, 222, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d99e4d", endColorstr="#8028de", GradientType=1);
}

.socialMedia a.linkedin {
  background: #0077b5;
}

.socialMedia a.behance {
  background: #0057ff;
}

.socialMedia a.twitter {
  background: #1dabdd;
}

.loginLeftPanel {
  box-shadow: none !important;
}

.dialogOTP {
  padding: 20px 20px;
  text-align: center;
}

.dialogOTP .OTPBox {
  padding: 30px;
}

.saveSearch {
  display: inline-block !important;
  float: left !important;
  width: 33% !important;
}

.icon-saved {
  position: absolute;
  top: 12px;
  right: 28px;
}

.icon-common {
  position: absolute;
  top: 12px;
  right: 0px;
  margin: 0px;
}

.dialogOTP .btnCustom {
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  clear: both;
  pointer-events: auto !important;
  cursor: pointer !important;
}

.thankYouText {
  font-size: 1.1em !important;
}

.linkRow {
  width: 100%;
  float: left;
  clear: both;
}

.leftColLink {
  float: left;
  width: 60%;
  margin-right: 2%;
}

.rightColLink {
  float: left;
  width: 40%;
  text-align: right;
}

.mainTitle .MuiListItemButton-root {
  background: none;
}

.mainTitle .MuiListItemButton-root {
  background: none;
  color: #dde6e2 !important;
}

.mainTitle .css-claira {
  width: 30px;
  height: 45px;
  color: #fff;
}

.logoHeader {
  background: #f8f8f8;
  height: 80px;
  padding-top: 5px !important;
}

.headerWrapper {
  height: 80px !important;
  width: 100% !important;

  background: #f8f8f8;
}

.headerInner {
  background: #ffffff;
  height: 80px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sideBar {
  background: #001892;
}

.sideBar .MuiCollapse-wrapper {
  padding-left: 10px;
}

.sideBar a {
  /* color: #85a094 !important; */
  color: #dde6e2 !important;
  text-decoration: none !important;
  height: 30px;
}

.sideBar a.active {
  color: #ffc300 !important;
  background: none;
}

.sideBar .dropdownList {
  margin-bottom: 20px;
}

.sideBar .css-nodkat {
  width: 8px;
  height: 8px;
  border-radius: 0;
  background-color: #919eab;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: 5px;
}

.sideBar a.active div span {
  width: 8px;
  height: 8px;
  border-radius: 0;
  background-color: #ffc300;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: scale(1);
}

.sideBar .css-h3bten-MuiButtonBase-root-MuiIconButton-root{
  color: #dde6e2 !important;
}

/* .css-men9i-MuiPaper-root-MuiDrawer-paper{
    border-right:none!important;
    width:281px!important;
    top:80px!important;
} */
.avtar .MuiAvatar-root {
  background: #001892;
  color: #fff;
}

.profileBtn .MuiBadge-root {
  color: #001892;
  margin-right: 20px;
}

.profileBtn .MuiBadge-root svg {
  width: 30px;
  height: 30px;
}

.profileBtn .MuiBadge-badge {
  background-color: #001892;
  color: #fff;
  top: 22px;
  right: 0;
}

.sumbitBtn {
  background-color: #001892 !important;
}

.tableCustom {
  text-align: left;
}

.tableCustom th {
  font-weight: bold;
  width: 30%;
  /* border-right:1px solid #999; */
}

.calenderDays {
  width: 75px !important;
  border: 1px solid #d5d5d5 !important;
  display: inline-block;
  margin-right: -5px !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}

.calenderDays:first-child {
  border-radius: 5px 0 0 5px !important;
}

.calenderDays:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.todayBtn {
  border: 1px solid #d5d5d5 !important;
  border-radius: 5px !important;
}

.dateArrowHolder button {
  border: 1px solid #d5d5d5 !important;
  border-radius: 0;
}

.dateArrowHolder .forwardArrow {
  margin-left: -1px !important;
  border-radius: 0 5px 5px 0 !important;
}

.dateArrowHolder button:first-child {
  border-radius: 5px 0 0 5px !important;
}

.fc .fc-col-header th {
  border-color: #f5f5f5 !important;
  background: #d3e1ea;
}

.tableCustom th,
.tableCustom td {
  padding: 15px 5px;
  /* border-bottom:1px solid #999; */
}

.tableCustom tr:last-child th,
.tableCustom tr:last-child td {
  padding: 15px 5px;
  border-bottom: none;
}

.pl-0 {
  padding-left: 0 !important;
}

.tableCustom .websiteName {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.errorMessage {
  position: absolute;
  left: 0px;
  width: 130px;
  top: 40px;
  font-size: 11px;
  color: #ffa707;
}

.urlInput {
  margin-right: 10px !important;
  position: relative;
  display: inline-flex;
  width: 160px;
}

.urlInput input {
  float: left;
  padding: 8px 10px;
  border-radius: 4px !important;
  width: 160px;
}

.doneBlock {
  margin: 20% 0 0 0;
  box-shadow: 0 0 15px #d5d5d5 !important;
  margin-left: -100px;
  margin-right: 100px;
}

.leftDoneBlock {
  height: 100vh;
}

.tableResponsive {
  overflow: auto;
  max-width: 100%;
}

.graph {
  max-width: 500px;
  margin: 0 auto;
}

.progressWrapper {
  position: relative;
}

.progressText {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 2px;
  color: #fff;
}

.css-1f2yong-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  box-shadow: none !important;
}

@media (max-height: 750px) {
  .loginBlock .loginPanel {
    height: auto;
    position: relative;
    /* margin-top: 20px;
        margin-bottom: 20px; */
  }
}

.leftDoneBlock {
  height: 100vh;
}

.tableResponsive {
  overflow: auto;
  max-width: 100%;
}

.graph {
  max-width: 500px;
  margin: 0 auto;
}

.talentTree li {
  position: relative;
}

.iconCustom {
  width: auto;
  position: absolute;
  z-index: 9;
  right: 15px;
  top: 8px;
}

.titlesSetting {
  position: absolute;
  background: #fff;
  top: -11px;
  z-index: 99;
  left: 16px;
  padding: 0 15px;
}

.boxSetting {
  overflow: unset !important;
}

.settingNav {
  border: none;
  width: 100px;
  margin: 10px 0 20px 0;
}

.settingNav a {
  float: left;
}

.settingNav .activetab {
  background: none;
  color: #0b4029 !important;
  border-bottom: 2px solid #0b4029;
  border-radius: 0 !important;
}

.fullWidth {
  width: 100%;
}

/* InBox Css Start  18-04-2022*/
.inboxTab {
  background: #001892;
  border: none;
}

.inboxTab h6 {
  color: #fff;
}

.inboxTab .activetab {
  background: #44577a;
  border: 2px solid #44577a;
}

.inboxWrapper {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.inboxWrapper:hover,
.inboxSideTable:hover {
  background: #d3e1ea;
}

.inboxWrapper h3,
.inboxWrapper p {
  color: #44577a;
}

.inboxWrapper h3 {
  font-size: 17px;
  margin-bottom: 10px;
}

.inboxWrapper p {
  font-size: 15px;
}

.LeftSideHolder {
  position: absolute;
  width: 80px;
  left: 10px;
  top: 15px;
  text-align: left;
}

.iconBlock {
  position: absolute;
  width: 80px;
  right: 0;
  top: 15px;
  text-align: left;
}

.iconBlock .iconRCol {
  font-size: 18px;
  text-align: right;
  margin-left: 10px;
}

.moreStatus {
  position: absolute;
  top: 9px;
  right: -9px;
}

.LeftSideHolder img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.LeftSideHolder span {
  display: inline-block;
}

.LeftSideHolder p {
  margin-top: 5px;
}

.Fcontainer {
  max-width: 1200px;
  border: 0.5px solid #d3e1ea;
  border-radius: 16px;
}

.LeftBox {
  padding: 0px;
  border: 0.1px solid #d3e1ea;
  border-left: none;
  border-right: none;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.LeftBox tr td {
  padding: 5px;
}

.LeftBox tr td.rightTD {
  text-align: left;
  padding-right: 20px;
}

.Leftimg {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
}

.contentBox h5 {
  font-size: 16px;
  color: #44577a;
  font-weight: 500 !important;
}

.contentBox p {
  font-size: 16px;
  color: #8693aa;
}

.LeftTCell {
  margin: 0px;
  padding: 0px;
}

.centerCell h5 {
  font-size: 16px;
  color: #44577a;
  font-weight: 500 !important;
}

.centerCell p {
  font-size: 16px;
  color: #8693aa;
}

.LeftTCell img {
  width: 42px;
  height: 42px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 15px;
}

.msgcount {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #001892;
  text-align: center;
  color: white;
  margin-left: 0;
}

#RightCard {
  padding: 0px;
  color: #fff;
  box-shadow: none !important;
  background: #001892;
  width: 100%;
  height: 650px;
}

.NotificationRightCard {
  margin-bottom: 1em;
  border: 0.1px solid #d3e1ea;
}

.NotificationRightCard tr td {
  padding: 5px;
}

.NotificationRightTRow {
  margin: 0em;
  font-size: 10px;
}

.NotificationRightTRow img {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  padding: 5px;
}

.notificationcontainer {
  border-left: 0.5px solid #d3e1ea;
  margin: 0px;
  padding: 0px;
  border-color: 0.5px solid #d3e1ea;
}

/* InBox Css End  18-04-2022*/

/*  Subscription Css Start 18-04-2022 */
.FirstContainer {
  text-align: center;
  margin: 2em;
}

.SecondContainer {
  text-align: center;
  display: block !important;
}

.ContentSpan {
  padding: 20px 20px 0;
  float: left;
  margin-top: 60%;
  text-align: center;
  font-size: 16px;
  width: 100%;
}

#leftCard {
  max-width: 100%;
  height: 650px;
  background-color: #e8e5ff;
  text-align: center;
}

.LeftSideBox {
  padding: 0px;
  border: 0.1px solid #d3e1ea;
  border-left: none;
  border-right: none;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.LeftSideBox.active {
  background-color: #00ab55;
}

.LeftSideBox tr td {
  padding: 5px;
}

.LeftSideBox tr td.MsgcountBox {
  text-align: right;
  padding-right: 20px;
}

.leftsideBox {
  margin: 2em;
  padding: 1em;
  text-align: center;
}

.LeftSideTable tr td {
  border: none;
}

.LeftSideCell {
  margin: 0px;
  padding: 0px;
}

.LeftSideCell img {
  width: 42px;
  height: 42px;
  line-height: 50px;
  border-radius: 50%;
  padding: 5px;
  margin-left: 15px;
}

.leftcardgrid {
  margin: 2em;
  padding: 1em;
  height: 300px;
}

#listUl {
  text-align: left;
}

#listUl li {
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
}

#rightlistUl {
  text-align: left;
  color: #000000;
}

#rightlistUl li {
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
}

.leftButton {
  width: 170px;
  margin: 130px auto 0;
  text-align: center;
  background: #000000 !important;
  border-radius: 5px !important;
}

.leftButton button {
  color: #fff !important;
}

.leftGrid {
  text-align: center;
  display: inline-block;
  margin: 0px 20px;
}

.RightGrid {
  max-width: 350px;
  text-align: center;
  display: inline-block;
  margin: 30px 20px;
}

.rightBlockInbox {
  padding: 20px;
}

#RightCard button {
  color: #212b36;
}

.rightsideBox {
  margin: 2em;
  padding: 1em;
  text-align: center;
}

.rightsideBoxGrid {
  margin: 2em;
  padding: 1em;
  height: 300px;
}

.rightList {
  font-size: 10px;
  margin-right: 10px;
}

.rightButton {
  width: 170px;
  margin: 128px auto 0;
  text-align: center;
  color: #001892;
  border-radius: 5px !important;
}

.subsamount {
  font-size: 16px;
  font-weight: bold;
}

.rightGridInbox {
  border-left: 1px solid #d3e1ea;
}

.rightpannel {
  padding: 20px;
}

.rightpannelcard {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0 0 15px 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.rightpannelcardOne {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
  border-right: none;
}

.rightpannelcard-changes {
  padding: 0;
}

.rightpannelcard h3,
.rightpannelcard p {
  color: #44577a;
}

.rightpannelcard h3 {
  font-size: 17px;
  margin-bottom: 10px;
}

.rightpannelcard p {
  font-size: 15px;
}

.CardLeftSide {
  position: absolute;
  width: 80px;
  left: 10px;
  top: 15px;
  text-align: left;
}

.CardLeftSide img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

.CardLeftSide span {
  display: inline-block;
}

.CardLeftSide p {
  margin-top: 5px;
}

.IconBox {
  position: absolute;
  width: 50px;
  right: 0;
  top: 15px;
  text-align: left;
  font-size: 15px;
}

.IconBox .forIcon {
  font-size: 18px;
  text-align: right;
  margin-left: 10px;
}

/* -- Small devices (landscape phones, 768 and up)--*/
@media (max-width: 767.98px) {
  .rightBlockInbox {
    padding: 10px;
  }

  .rightGridInbox {
    border: none !important;
  }

  .leftcardgrid,
  .rightsideBoxGrid,
  #RightCard,
  #leftCard {
    height: auto;
  }

  .leftcardgrid,
  .rightsideBoxGrid {
    margin: 0 15px;
    padding: 0 15px;
  }

  #RightCard,
  #leftCard {
    padding-bottom: 20px;
  }

  #leftCard .subsamount {
    display: none;
  }

  .inboxWrapper {
    padding: 100px 10px 10px;
  }

  .LeftSideHolder {
    width: 125px;
  }

  main {
    padding-left: 10px;
    padding-right: 10px;
  }

  .inboxSideBar {
    width: 100%;
  }
}

/*  Subscription Css End 10-04-2022 */

/* --Extra small devices (portrait phones, less than 576px) --*/
@media (max-width: 575.98px) {
  .noPaddingXS {
    padding: 0 !important;
  }

  .noMarginXS {
    margin: 0 !important;
  }

  .socialMedia svg {
    display: block;
    width: 100%;
  }

  .dialogOTP .OTPBox {
    padding: 0;
  }

  .dialogOTP .OTPBox .MuiContainer-maxWidthLg {
    padding-left: 0;
    padding-right: 0;
  }

  .optInput {
    margin-left: 15px !important;
  }
}

/* -- Small devices (landscape phones, 576px and up)--*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .noPaddingSM {
    padding: 0 !important;
  }

  .noMarginSM {
    margin: 0 !important;
  }
}

/* -- Small devices (landscape phones, 768 and up)--*/
@media (max-width: 767.98px) {
  .graph {
    max-width: 300px;
  }

  .leftDoneBlock {
    height: auto;
  }

  .doneBlock {
    margin: 20px 4% 20px 4%;
    box-shadow: 0 0 15px #d5d5d5 !important;
    max-width: 92%;
  }

  .loginBlock {
    width: 100% !important;
  }

  .loginBlock .loginPanel {
    width: 100%;
    top: 100px;
    position: relative;
    margin-bottom: 130px;
    padding: 20px;
    height: auto;
  }

  .logiLoginHolder .MuiBox-root {
    margin: 0 auto;
  }

  .logiLoginHolder {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
  }

  .logiLoginHolder img {
    display: block;
  }

  .socialMedia {
    margin: 15px -25px 0;
  }
}

/* -- Medium devices (tablets, 768px and up)--*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .leftDoneBlock {
    height: auto;
  }

  .doneBlock {
    margin: 20px 4% 20px 4%;
    box-shadow: 0 0 15px #d5d5d5 !important;
    max-width: 92%;
  }

  .loginBlock {
    width: 100% !important;
  }

  .loginBlock .loginPanel {
    width: 70%;
    top: 100px;
    position: relative;
    margin-bottom: 130px;
    padding: 20px;
    height: auto;
  }

  .logiLoginHolder .MuiBox-root {
    margin: 0 auto;
  }

  .logiLoginHolder {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
  }

  .logiLoginHolder img {
    display: block;
  }
}

/* -- Large devices (desktops, 992px and up)--*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .loginBlock {
    width: 90% !important;
  }

  .loginBlock .loginPanel {
    width: 100%;
    top: 0;
    position: relative;
    margin: 30px 0;
  }

  .logiLoginHolder {
    display: block;
  }
}

@media (max-width: 1199.98px) {
  .headerInner .logiLoginHolder {
    display: none;
  }
}

.fc-day-past {
  background-color: #e5e4e2;
}

.margin4 {
  margin: 16px;
}

.margin2 {
  margin: 8px;
}

.card-class {
  height: 89px !important;
  cursor: pointer;
  /* background-color:#BDB4FC !important; */
}

.buttonset {
  display: flex;
  gap: 16px;
}

.tableCustom1 {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tableCustom2 {
  border: 1px solid #d3e1ea;
  width: 90%;
  border-collapse: collapse;
}

.tableCustom1 th {
  font-weight: bold;
  /* color: #44577A; */
  padding-left: 15px !important;
}

.tableCustom1 td {
  /* color: #44577A; */
}

.tableCustom1 th,
td {
  padding: 15px 15px;
  border-bottom: 1px solid #d3e1ea;
  border-left: 1px solid #d3e1ea;
}

.tableCustom1 .table-main-title {
  text-align: initial !important;
}

.jobModelButton {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}

.dateBetweenTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginTop2 {
  margin-top: 16px;
}

/* .profileImgLocation1 {
    padding: 16px 16px 16px 16px !important;

} */

.setPercent {
  display: flex;
  align-items: center;
  gap: 15px;
}

.card-class-seek {
  width: fit-content;
}

.card-seek {
  overflow-y: auto !important;
}

.modal-button-class {
  display: flex;
  gap: 15px;
  align-items: center;
}

.card-title {
  display: flex;
  align-items: center;
}

.candiadateListPaddingSet {
  padding: 0px !important;
}

.textAlignCenterClass {
  text-align: center !important;
}

.cancelBtn {
  background-color: #f8f8f8;
  color: #000 !important;
}

.dFlex {
  display: flex;
  gap: 8px;
}

.profileType {
  justify-content: left !important;
  border: 1px solid #c4cdd5;
  box-shadow: none !important;
  width: 360px;
}

.followBackBtn {
  background-color: #001892 !important;
  color: #ffffff !important;
}

.d-flex {
  display: flex;
}

.tableJobPost th,
.tableJobPost td {
  text-align: center;
}

.imageTable {
  width: 150px;
  height: 117px;
  object-fit: contain;
}

.box-image {
  display: flex;
  justify-content: center;
}

.marginBottom2 {
  margin-bottom: 8px;
}

.marginBottom4 {
  margin-bottom: 16px;
}

.rightpannelcardOne {
  position: relative;
  border: 1px solid #cce0ff;
  border-radius: 10px;
  width: 100%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin: 0;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

.overflow-card {
  overflow-y: scroll !important;
}

.loading_button:hover {
  background-color: #001892 !important;
}

.qustion-flex {
  display: flex;
  justify-content: space-between;
}

.talentSaveSearchButton {
  background-color: #5d45f8;
  height: 53px;
  width: 3%;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  right: 50%;
}

.css-1simz5-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #a99cf8 !important;
}

.listCat {
  float: left;
  /* margin: 0 10px 10px 0; */
  border: 1px solid #d5d5d5;
}

.listCatData {
  width: auto !important;
  float: left !important;
  margin: 0 10px 10px 0 !important;
}

.checkIcon {
  display: none !important;
}

.industryListLeft {
  border: 1px solid #d5d5d5;
  /* margin: 22px 14px !important; */
  /* padding: 0 !important; */
  border-radius: 10px;
}

.industryListRight {
  border: 1px solid #d5d5d5;
  /* margin: 22px 14px !important;
    padding: 0 !important; */
  border-radius: 10px;
}

.click-state {
  font-weight: bold;
  color: #3b5999;
  background: #ddd !important;
}

.click-state:hover,
.base-state:hover {
  background: #ddd !important;
}

.base-state {
  font-weight: normal;
  color: #000000;
}

.cancelBtn {
  background-color: rgba(145, 158, 171, 0.8) !important;
  color: #fff !important;
}

.CropperSize .reactEasyCrop_CropArea {
  height: 250px !important;
  width: 250px !important;
}

.image-selected img {
  object-fit: fill !important;
}

.loginMessage {
  height: 20px;
}

.loginMessage .alertMessage {
  padding: 0 6px;
}

.loginMessage .alertMessage .MuiAlert-message {
  padding: 5px 0;
}

.loginMessage .alertMessage .MuiAlert-icon {
  padding: 4px 0;
}

.class-profile-card {
  width: 100%;
  height: 100%;
}

.class-profile-card-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.loginInputHolder {
  position: relative !important;
  margin-bottom: 10px !important;
}

.loginInputHolder p {
  position: absolute;
  left: 0;
  bottom: -20px;
}

.confirm-pass-screen .MuiFormHelperText-contained {
  position: absolute !important;
  left: 0 !important;
  bottom: -35px !important;
}

/* .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 5px;
      cursor: pointer;
     opacity:0;
     height:100%;
     width:100%;
  } */
.projectImgHolder .thumbHolder {
  position: relative;
  height: 350px;
  width: 200px;
}

.thumbHolder {
  position: relative;
}

.bannerImageHolder {
  position: relative;
  height: 100%;
  width: 100%;
}

.projectImgHolder .thumbHolder:hover .placeholder,
.bannerImageHolder:hover .placeholder {
  opacity: 1;
}

.projectImgHolder .placeholder {
  height: 350px;
  width: 200px;
}

.projectImgHolder .thumbHolder .dropAreaProjectThumb {
  height: 350px;
  width: 200px;
  padding: 90px 5px;
}

.projectOuterThumb img {
  object-fit: cover;
  height: 350px;
}

.projectOuterThumb {
  height: 350px;
  width: 200px;
}

.projectOuterThumbHolder {
  height: 450px;
  width: 200px;
  margin: 0 8px;
}

.cards-photos video {
  width: 340px;
  height: 100%;
}

.videoThumbProject {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding: 0px;
  margin: 4px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  border: solid 1px rgba(145, 158, 171, 0.24);
}

.closeIconvideoThumbProject {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: #637381;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  top: 6px;
  padding: 2px;
  right: 6px;
  position: absolute;
  color: #fff;
  background-color: rgba(22, 28, 36, 0.72);
}

.defaultPlaceholder {
  background-image: url("https://zone-assets-api.vercel.app/assets/img_placeholder.svg");
}

@media (max-width: 479.99px) {
  .socialMedia a {
    font-size: 14px;
  }
}

.companyHeader {
  height: 100px;
  width: 100%;
  color: #ffffff;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.companySlider {
  width: 100%;
  height: 100%;
}

.companyAbout {
  width: 100%;
  padding-top: 5%;
  color: #000000;
  padding-left: 10%;
}

.columCount {
  column-count: 3;
}

.textData {
  padding: 3% 10% 5% 10%;
  /* // background-color: #f8f8f8; */
}

.setContentBackground {
  background-color: #eee;
  padding: 40px;
}

.enquiryClass {
  background-color: #000000;
  height: 300px;
  color: #ffffff;
  width: 100%;
}

.menuHolder {
  text-align: right;
}

.menuHolder a {
  display: inline-block;
  padding: 0 20px;
}

.link {
  color: #ffffff;
  padding-left: 30px;
}

.sendEnquiryForm {
  padding-left: 10%;
  margin-top: 2%;
}

.sendEnquiryForm .MuiInputBase-formControl {
  border-radius: 0;
}

.sendEnquiryForm .MuiInputBase-formControl {
  background: #fff !important;
}

.sendEnquiryForm .MuiFormControl-fullWidth {
  background: none;
}

.sendEnquiryForm .Mui-error {
  margin-left: 0;
  margin-top: 3px;
}

.blueBox {
  background: url("../src/images/companyBackground.png") no-repeat 0 0;
  width: 100%;
  padding: 50px 100px 50px;
  color: #fff;
}

.blueBox1 {
  background: url("../src/images/companyBackground.png") no-repeat 0 0;
  width: 100%;
  padding: 40px 100px 50px;
  color: #fff;
}

.blueBox2 {
  background: url("../src/images/companyBackground.png") no-repeat 0 0;
  width: 100%;
  padding: 20px 100px 35px;
  color: #fff;
  background-size: cover;
}

.blueBox3 {
  background-color: #eee;
  width: 100%;
  padding: 20px 100px 35px;
  color: #000000;
}

.companyProfileDataBox {
  background: #3366ff;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
}

.companyProfileDataBox p {
  font-size: 15px;
  font-weight: normal;
}

.companyProfileDataBox h5 {
  font-size: 17px;
}

.enquiryWrapper {
  background: #000;
  /* margin:0 0 50px; */
  padding: 30px;
}

.sendEnquiry {
  font-size: 18px;
  color: #ffffff;
  padding-left: 10%;
}

.companyProject {
  width: 100%;
  color: #ffffff;
}

.companyServices {
  color: #000000;
}

.sliderImageBox {
  width: 96%;
  padding: 0 10px;
  margin-bottom: 0;
}

.slick-slide img {
  width: 100%;
  padding: 0;
}

.locationBannerSlider .slick-slide img {
  width: 100%;
  padding: 0;
}

.serviceSliderInfo {
  background: #fff;
  padding: 10px;
  color: #000;
}

.companyLogo {
  position: relative;
  z-index: 999;
  background: #fff;
  width: 100px;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

.blueBox2 .slick-prev:before,
.blueBox2 .slick-next:before {
  color: #fff;
}

.sliderImageBoxJob {
  background: #fff;
  width: 90% !important;
  margin: 0 5%;
}

.sliderImageBoxJob img {
  width: 90%;
  margin: 0 auto;
  display: block;
}

.serviceSliderInfo span {
  display: block;
}

.page-para-adj {
  padding-left: 24px;
  padding-right: 24px;
}

.page-para-adj-main {
  margin: 50px;
}

.para-adj-page {
  margin-top: 12px;
}

.newspaper {
  column-count: 2;
}

.page-para-adj-yel {
  padding-left: 74px;
  padding-right: 74px;
  background-color: black;
  padding-bottom: 30px;
}

.page-para-adj-picture {
  padding-left: 74px;
  padding-right: 74px;
  padding-bottom: 50px;
}

.padding-poster {
  padding-top: 50px;
}

.slid-images {
  padding-right: 12px !important;
  width: 320px !important;
  height: 137px !important;
  margin-bottom: -10px;
}

/* .slick-list .slick-track{
    height: 185px !important;
} */
.prviewSpan span {
  padding-top: 0px !important;
}

.prviewUl {
  margin-bottom: 0px !important;
}

.vid-details {
  height: 50px;
  background-color: #ffffff;
  margin-right: 12px !important;
}

.slick-next:before {
  color: #000000;
}

.slick-prev:before {
  color: #000000;
}

.filertDiv label {
  margin-right: 0px !important;
  display: unset;
}

.videoThumbPreview {
  width: calc(100% - 12px);
  height: 136px;
  margin-bottom: -5px;
  background: #f5f5f5;
  max-height: 140px;
  vertical-align: text-bottom;
  cursor: zoom-in;
  object-fit: cover;
}

.projDesign {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.serviceProfileImg {
  width: 200px;
  height: 350px;
}

.serviceProfileDesc {
  max-width: 23.333333% !important;
}

.serviceProfileDescLength {
  flex-basis: 74.666667% !important;
  max-width: 73.666667% !important;
}

.classBgColor .MuiDialog-paper {
  background-color: #000;
  color: #f5f5f5;
}

.showHalfcontent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 195px;
}

.showHalfcontentMiddle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 400px;
}

.align-title-center {
  display: flex;
  justify-content: center;
}

.followerIconPurple {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconPurple::after {
  background: url("../src/images/perpal.png") no-repeat;
  content: "";
  width: 33px;
  height: 31px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.followerIconBlue {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconBlue::after {
  background: url("../src/images/blue.png") no-repeat;
  content: "";
  width: 33px;
  height: 31px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.followerIconGreen {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 100px;
}

.followerIconGreen::after {
  background: url("../src/images/green.png") no-repeat;
  content: "";
  width: 33px;
  height: 31px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.popularStrip {
  top: -25px;
  left: 13px;
}

.image-edit-service .dropAreaProjectThumb {
  height: 350px;
  width: unset !important;
  padding: 90px 5px;
}

.image-edit-service .thumbHolder {
  width: unset !important;
}

.image-edit-service .placeholder {
  width: unset !important;
}

.textAlignLeft {
  text-align: left !important;
  padding-left: 15px;
}

.tableCustomCandidateView {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tableCustomCandidateView th {
  font-weight: bold;
  padding: 15px 15px;
  border-bottom: 1px solid #d3e1ea;
}

.tableCustomCandidateView tr td {
  padding: 15px 15px;
  border-left: none !important;
  /* border-left:1px solid #D3E1EA;  */
}

.marginCandidateView {
  margin: 8px 8px 8px 0px;
}

.profileListView {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  border: 1px solid #999;
  overflow: hidden;
}

.profileListView .profileListViewThumb {
  border-radius: 0;
}

.profileListViewThumb {
  border-radius: 50%;
}

.accordanceList::before {
  height: 0 !important;
}

.forwardArrowCircel {
  background-color: #cec7fd;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 3px 4px 4px;
}

.image-edit-service .dropAreaProjectThumb {
  height: 350px;
  width: unset !important;
  padding: 90px 5px;
}

.image-edit-service .thumbHolder {
  width: unset !important;
}

.image-edit-service .placeholder {
  width: unset !important;
}

.selectHolder {
  position: relative;
}

.selectHolder svg {
  position: absolute;
  /* right: 8px; */
  left: 185px;
  top: 20px;
  width: 18px;
}

.jobActionButton {
  -webkit-appearance: none;
  border-radius: 8px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  border: 1px solid #ddd;
  width: 185px;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.jobActionButton:focus {
  outline: none;
  border: 1px solid #333;
}

.confirmSelectDays select {
  padding: 9px !important;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #001892;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #001892;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #001892;
  border-color: #001892;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.searchButton {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  background-color: #cec7fd !important;
  height: 53px !important;
  border-radius: 0 5px 5px 0px !important;
}

.customContainer {
  width: 80% !important;
}

.tableCustom3 {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
  max-width: 200px !important;
  float: right;
  margin-right: 20px !important;
}

.videoList {
  width: 290px;
  position: relative;
  margin-right: 2%;
  float: left;
  margin-bottom: 15px;
}

.awardBox {
  width: 31.5%;
  margin: 2% 1.5% 0 0;
  float: left;
  background: #f8f8f8;
}

.indicator button {
  width: 50%;
}

.indicator .MuiTabs-indicator {
  background-color: #001892 !important;
}

.indicator button {
  width: 20%;
  border: 1px solid #d3e1ea;
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
}

.indicator button.Mui-selected {
  background: #001892;
  border-color: #001892;
  color: #fff;
}

.followRow {
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.followBox {
  display: block;
}

.followBox p {
  font-size: 14px;
}

/* -- Small devices (landscape phones, 576px and up)--*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .videoList {
    width: 48%;
  }

  .awardBox {
    width: 48%;
    margin: 2% 1.5% 0 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .videoList {
    width: 31%;
  }

  .awardBox {
    width: 31%;
    margin: 2% 1.5% 0 0;
  }
}

@media (min-width: 992.98px) and (max-width: 1199.98px) {
  .tableCustom3 {
    border: 1px solid #d3e1ea;
    width: 100%;
    border-collapse: collapse;
    max-width: 200px !important;
    float: right;
    margin-right: 20px !important;
  }
}

/* -- Small devices (landscape phones, 576px and up)--*/
@media (min-width: 300px) and (max-width: 767.98px) {
  .customContainer {
    width: 100% !important;
  }

  .customBox {
    flex-wrap: wrap;
  }

  .tableCustom3 {
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    margin-right: 0 !important;
    float: none;
    width: 94% !important;
    margin-left: 3% !important;
  }

  .sumbitBtn {
    margin: 0 17px 15px !important;
  }
}

@media (min-width: 300px) and (max-width: 767.98px) {
  .customContainer {
    width: 100% !important;
  }

  .customBox {
    flex-wrap: wrap;
  }

  .tableCustom3 {
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    margin-right: 0 !important;
    float: none;
    width: 94% !important;
    margin-left: 3% !important;
  }

  .sumbitBtns {
    margin: 0 17px 0px !important;
  }
}

.linkCssChanges {
  width: 100%;
}

.topBox {
  padding-bottom: 40px;
}

.lastCol {
  position: relative;
}

.bottomRow {
  position: absolute;
  bottom: 0;
}

.wordkExpTable {
  min-width: 100% !important;
  border: 1px solid #eee;
  border-left: none;
  border-bottom: none !important;
}

.wordkExpTable td {
  border-bottom: 1px solid #eee !important;
}

.selectClass {
  padding: 12px;
  margin-left: 24px;
  margin-top: 5px;
  width: 200px;
  border-radius: 5px;
  font-weight: 600;
}

.candidateInterviewSchedule {
  border: 3px solid #b9b0f9;
  background-color: #dad6fa;
  padding: 10px;
}

.css-j88s13-MuiPickersToolbar-root-MuiDatePickerToolbar-root
  .MuiPickersToolbar-penIconButton {
  display: none !important;
}

.css-hrm44d-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

.GridPagination .MuiIconButton-root {
  color: #000;
  display: inline !important;
}

.hidePlaceholder .placeholder {
  display: none;
}

.hidePlaceholder .thumbHolder .dropAreaProjectThumb {
  width: 180px;
}

.mainNavJob {
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #f8f8f8 !important;
  padding: 6px;
  box-shadow: 0 0 5px #d5d5d5;
  cursor: pointer;
}

.activetabjob {
  background-color: #fff;
  color: #000 !important;
  padding: 8px;
  border-radius: 5px;
}

.css-1vofi97 .MuiPickersToolbar-penIconButton {
  position: relative;
  top: 4px;
  display: none !important;
}

.css-10dohqv {
  user-select: none;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  /* display: none !important; */
}

.css-1na781p {
  box-sizing: border-box;
  display: none !important;
  flex-flow: column wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 0%;
}

.tableCustomView {
  border: 1px solid #d3e1ea;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.tableCustomView th {
  font-weight: bold;
  /* color: #44577A; */
  padding-left: 15px !important;
}

.tableCustomView td {
  /* color: #44577A; */
}

.tableCustomView th,
td {
  padding: 15px 15px;
  border-bottom: 1px solid #d3e1ea;
  border-left: 1px solid #d3e1ea;
}

.tableCustomView .table-main-title {
  text-align: initial !important;
}

.viewBorder {
  border-right: 1px solid #d3e1ea;
  width: 100%;
}

.messageWidthSet {
  width: 100%;
}

.rightpannelcardMessage {
  position: relative;
  border: 2px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 10px 20px 10px 20px;
  min-height: 110px;
  margin: 0;
  /* transition: all 0.3s ease;
        box-shadow: none!important;
        border-right: none; */
}

.activeViewJobTap {
  background-color: #001892;
  padding: 5px 30px 5px 30px;
  color: #fff;
}

.css-6yacme-MuiPaper-root {
  background-color: #fff;
  color: #212b36;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  width: 100%;
  height: 230px;
  overflow: auto;
  border: 1px solid !important;
}

.css-12elb7u {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  width: 200px;
  height: 230px;
  overflow: auto;
  border: 1px solid !important;
}

.isLogoBoxShadow {
  box-shadow: 0 0 10px #d8d7d9 !important;
}

.projectViewImgHolder .thumbHolder {
  position: relative;
  height: 350px;
  width: 200px;
}

.projectViewImgHolder .thumbHolder:hover .placeholder,
.bannerImageHolder:hover .placeholder {
  opacity: 1;
}

.projectViewImgHolder .placeholder {
  height: 350px;
  width: 200px;
}

.projectViewImgHolder .thumbHolder .dropAreaProjectThumb {
  height: 350px;
  width: 200px;
  padding: 90px 5px;
}

.hideViewPlaceholder .placeholder {
  display: none;
}

.hideViewPlaceholder .thumbHolder .dropAreaProjectThumb {
  width: 210px;
}

.setSelect {
  padding: 12px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border-color: gray;
}

.leftlinkRow {
  float: left;
  width: 50%;
  padding-left: 20px;
}

.leftEmailColLink {
  float: left;
  width: 37%;
  padding-left: 35px;
}

.leftResetColLink {
  float: left;
  width: 30%;
}

.sliderImageBoxProjectJob {
  background: #fff;
  width: 90% !important;
  margin: 0 5%;
  /* height:100%!important; */
}

.sliderImageBoxProjectJob img {
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 250px;
}

.CalenderCard {
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 10px;
  border: 0.5px solid #c9d7d4;
}

.calenderBox {
  width: 40px;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  color: White;
}

.css-w4xala {
  padding: 92px 16px !important;
  /* padding: 75px 16px !important; */
  /* width: calc(100% - 280px); */
  transition: margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.css-1qm1lh {
  margin-bottom: 0px !important;
}

.css-1sjqapr-MuiPaper-root-MuiDrawer-paper {
  border-right-style: none !important;
  border-right: #44577a !important;
  background-color: #44577a !important;
}

.css-1porhrx {
  border-right-style: none !important;
  border-right: #44577a !important;
  background-color: #44577a !important;
}

.css-1v0hpbg-MuiTabs-indicator {
  height: 2px !important;
  bottom: 6px !important;
}

.css-ee9i3f {
  height: 2px !important;
  bottom: 6px !important;
}

.profilebtn {
  background-color: #a6add3 !important;
  color: #001892 !important;
}

.tableInviteStatus {
  /* min-width: 1200px !important; */
  width: 100%;
  text-align: left;
  border: 1px solid #d3e1ea;
  border-radius: 10px !important;
  border-collapse: collapse;
}

.tableInviteStatus th {
  /* font-weight:bold; */
  padding-left: 15px !important;
}

.tableInviteStatus th,
td {
  /* border:none; */
  padding: 12px 12px;
}

.tableInvititation {
  min-width: 1200px !important;
  width: 100%;
  text-align: left;
  border: 1px solid #d3e1ea;
  border-radius: 10px !important;
  border-collapse: collapse;
}

.tableInvititation th {
  /* font-weight:bold; */
  padding-left: 15px !important;
  background-color: #001892 !important;
  color: #fff;
}

.tableInvititation th,
td {
  /* border:none; */
  padding: 12px 12px;
}

.InviteBtn {
  background-color: #001892 !important;
}

.PrivatePickersToolbar-dateTitleContainer
  .css-hrm44d-MuiButtonBase-root-MuiIconButton-root {
  display: none !important;
}

.PrivatePickersToolbar-dateTitleContainer .PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.PrivatePickersToolbar-dateTitleContainer .css-1gwtl1m {
  display: none !important;
}

.css-ctu4nj .PrivateDatePickerToolbar-penIcon {
  display: none !important;
}

.rightpannelmsg {
  position: relative;
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 90%;
  padding: 10px 100px 10px 110px;
  min-height: 110px;
  margin-left: 5%;
  transition: all 0.3s ease;
  box-shadow: none !important;
  /* border-right: none; */
}

.MessageBtn {
  background-color: #ada1fb !important;
  color: #08080c !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
}

.css-oda9m7 .css-j7qwjs .css-zv8h38 .MuiPickersToolbar-penIconButton {
  display: none !important;
}

.urlWidth {
  max-width: 46% !important;
}

.databoxAlbum {
  position: absolute;
  top: 0;
  left: 10;
  right: 0;
  padding: 10px;
  color: #fff;
  z-index: 2;
}

.calenderTimelineDays {
  width: 75px !important;
  border: 1px solid #d5d5d5 !important;
  display: inline-block;
  margin-right: -5px !important;
  font-weight: normal !important;
  border-radius: 0 !important;
}

.calenderTimelineDays:first-child {
  border-radius: 5px 0 0 5px !important;
}

.calenderTimelineDays:middle-child {
  width: auto !important;
}

.calenderTimelineDays:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.projectImg .thumbHolder {
  position: relative;
  height: 350px;
  width: 250px;
}

.projectImg .thumbHolder:hover .placeholder,
.bannerImageHolder:hover .placeholder {
  opacity: 1;
}

.projectImg .placeholder {
  height: 350px;
  width: 250px;
}

.projectImg .thumbHolder .dropAreaProjectThumb {
  height: 350px;
  width: 250px;
  padding: 90px 5px;
}

.hidePlaceProjectImg .placeholder {
  display: none;
}

/* ---------------------------------------------------------------------------------------- */

.react-calendar-timeline {
  position: relative;
  top: 19%;
}

.react-calendar-timeline .rct-header-root {
  background-color: #001892 !important;
}

.rct-items div.rct-item {
  background: #44577a !important;
  border-color: #44577a !important;
}

.css-1lwbda4-MuiStack-root > :not(style) + :not(style) {
  margin-top: 1rem !important;
}

/* main {
    width: 100% !important;
    padding: 1 !important;
} */

@media only screen and (max-width: 768px) {
  #settingAccountForm .sumbitBtn,
  #settingPasswordChangeform .sumbitBtn,
  #settingNotificationForm .sumbitBtn,
  #settingUserVisibleForm .sumbitBtn,
  #staffCrerateBtn,
  #userSearchTalentBtn,
  #userCrewPageBtn,
  #userOfficePageBtn,
  #userAdvTalSearchBtn,
  #userAdvCrewBtn,
  #empInfoBtn,
  #userAdvOffBtn {
    margin: 0 !important;
  }

  .profileType {
    width: 100%;
  }

  #regionalIndustryBtnCon button {
    min-width: 2rem !important;
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.viewBtn img {
  width: 3rem;
  /* width: 3.125rem; */
  height: 2.188rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.text-white {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .viewBtn img {
    width: 2rem;
    height: 1.5rem;
  }
}

.MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
  border: 1px solid #000;
  margin: 0 0.1rem;
  border-radius: 0.5rem;
}

.row-published {
  background-color: #f2f2f2;
}

.staffsumbitBtn {
  background-color: #001892 !important;
}

.staffNobtn {
  background-color: #a6add3 !important;
  color: #001892 !important;
}

.MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
  border: 1px solid #000;
  margin: 0 0.1rem;
  border-radius: 0.5rem;
}

.row-published {
  background-color: #f2f2f2;
}

#viewJobDashboardCal .css-169iwlq-MuiCalendarPicker-root {
  width: 100%;
}

.rct-items .rct-item {
  background-color: #d3e1ea !important;
}

.rct-items .rct-item:nth-child(1) {
  border-left: 5px solid #001892 !important;
}

/* .rct-items .rct-item:nth-child(1) {
border-left: 5px solid #001892 !important;
    width: 50px;
    width: 86px !important;
    word-break: break-all;
    left: 1132.03px;
    left: 1145.03px !important;
  } */

.rct-items .rct-item:nth-child(2) {
  border-left: 5px solid #1baa5a !important;
}

.rct-items .rct-item:nth-child(3) {
  border-left: 5px solid #5856d6 !important;
}

.rct-items div.rct-item h5,
.rct-items div.rct-item h6,
.rct-items div.rct-item p {
  color: #001892 !important;
  white-space: break-spaces;
}

.rct-items div.rct-item h5 {
  font-size: 0.875rem;
}

.rct-items .rct-item .daysIndicator {
  padding: 0.5rem;
}

.rct-items .rct-item .daysIndicator h6 {
  color: #fff !important;
}

.rct-items .rct-item:nth-child(1) .daysIndicator {
  background-color: #001892;
}

.rct-items .rct-item:nth-child(2) .daysIndicator {
  background-color: #1baa5a;
}

.rct-items .rct-item:nth-child(3) .daysIndicator {
  background-color: #5856d6;
}

.rct-header-root,
.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}

.dateArrowHolder {
  display: none !important;
}

#calenderViewBtns button {
  width: 10rem !important;
}

/* .calenderDays .css-1vkvqne-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: #001892 !important;
  color: #fff;
} */

/* --------------------------------------------------2303--------------------- */

.MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
  border: 1px solid #000;
  margin: 0 0.1rem;
  border-radius: 0.5rem;
}

.row-published {
  background-color: #f2f2f2;
}

#viewJobDashboardCal .css-169iwlq-MuiCalendarPicker-root {
  width: 100%;
}

.rct-items .rct-item {
  background-color: #d3e1ea !important;
}

.rct-items .rct-item:nth-child(1) {
  border-left: 5px solid #001892 !important;
}

.rct-items .rct-item:nth-child(2) {
  border-left: 5px solid #1baa5a !important;
}

.rct-items .rct-item:nth-child(3) {
  border-left: 5px solid #5856d6 !important;
}

.rct-items div.rct-item h5,
.rct-items div.rct-item h6,
.rct-items div.rct-item p {
  color: #001892 !important;
  white-space: break-spaces;
}

.rct-items div.rct-item h5 {
  font-size: 0.875rem;
}

.rct-items .rct-item .daysIndicator {
  padding: 0.5rem;
}

.rct-items .rct-item .daysIndicator h6 {
  color: #fff !important;
}

.rct-items .rct-item:nth-child(1) .daysIndicator {
  background-color: #001892;
}

.rct-items .rct-item:nth-child(2) .daysIndicator {
  background-color: #1baa5a;
}

.rct-items .rct-item:nth-child(3) .daysIndicator {
  background-color: #5856d6;
}

.rct-header-root,
.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}

.dateArrowHolder {
  display: none !important;
}

#calenderViewBtns button {
  width: 10rem !important;
}

.calenderDays.selected {
  background-color: #001892 !important;
}

/* -------------------------03-04-2023-------------------------------------------------------- */

header#companyProfileHeader {
  background-image: url("./images/headerbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container,
.container-fluid {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar .MuiGrid-container .MuiGrid-item .container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  width: 12%;
}

.navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  color: #000;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.show {
  display: block;
}

.hide {
  display: none;
}

#banner .container-fluid {
  height: 85vh;
  width: 100%;
}

#banner .container-fluid .slide {
  height: 85vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Compantprofileh2 {
  font-size: 1.5rem !important;
}

#articleContainer {
  column-count: 3;
  column-gap: 3%;
  column-width: 30%;
  max-height: 20vh;
}

#articleContainerAbout {
  column-count: 3;
  column-gap: 3%;
  column-width: 30%;
  max-height: 40vh;
  overflow: auto;
}

.companyServiceInfo {
  column-count: 2;
  column-gap: 3%;
  column-width: 47%;
  min-height: 20vh;
}

#articleContainer p {
  text-align: justify;
}

.bgCompanyProfile {
  width: 100%;
  height: auto;
  background-image: url("./images/companyBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.companyInfoBox {
  background-color: #3366ff !important;
}

.companyInfoBox h6 {
  font-size: 0.875rem !important;
}

.companyInfoBox h5 {
  font-size: 1rem !important;
}

.companyInfoBox h5,
.companyInfoBox h6 {
  color: #fff;
}

.companyInfosubh3 {
  font-size: 1.187rem !important;
}

#projectSlider .slick-arrow {
  color: #fff;
}

#projectSlider .slick-arrow.slick-next {
  right: 0 !important;
}

#projectSlider .slick-arrow.slick-prev {
  left: 0 !important;
}

#companyJobsTable th,
#companyJobsTable td {
  padding: 0.5rem 1rem;
  text-align: left;
}

.proTeamDashCard {
  min-height: 500px;
}

.proTeamDashCard #teamDashImgact {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 5%;
  display: flex;
  justify-content: center;
}

#teamDashboardTimeline .react-calendar-timeline {
  border: 1px solid #bbb;
  border-radius: 1rem;
}

#teamDashboardTimeline .react-calendar-timeline .rct-header-root {
  border-radius: 1rem 1rem 0 0;
}

/* #teamDashboardTimeline .react-calendar-timeline .rct-header-root div:first-child {
  color: #000 !important;
  display: flex;
  align-items: flex-end;
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  border-radius: 1rem 0 0 0;
  padding: 1rem;
} */

#teamDashboardTimeline
  .react-calendar-timeline
  .rct-header-root
  div:first-child {
  color: #000 !important;
  display: flex;
  align-items: flex-end;
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid #bbb;
  border-radius: 1rem 0 0 0;
  /* padding: 1rem; */
}

/* #teamDashboardTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header {
  border-radius: 0rem 1rem 0 0 !important;
} */

#teamDashboardTimeline
  .react-calendar-timeline
  .rct-header-root
  .rct-calendar-header {
  border-radius: 0.7rem 1rem 0 0 !important;
  border-bottom: 1px solid #ccc !important;
}

#teamDashboardTimeline .react-calendar-timeline .rct-header-root {
  background-color: #fff !important;
}

/* #teamDashboardTimeline .react-calendar-timeline .rct-header-root .rct-calendar-header div:first-child {
  display: none;
} */

#teamDashboardTimeline
  .react-calendar-timeline
  .rct-header-root
  .rct-calendar-header
  div:last-child {
  height: 100% !important;
}

#teamDashboardTimeline
  .react-calendar-timeline
  .rct-header-root
  .rct-calendar-header
  .rct-dateHeader
  span {
  font-size: 0.7rem !important;
  color: #000 !important;
}

#teamDashboardTimeline
  .react-calendar-timeline
  .rct-horizontal-lines
  div:last-child,
#teamDashboardTimeline
  .react-calendar-timeline
  .rct-sidebar
  div
  div.rct-sidebar-row:last-child {
  border-bottom: 0 !important;
}

.proTeamDashCard .chatInput {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
}

.proTeamDashCard .messageArea {
  height: 80.7%;
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.proTeamDashCard .messageArea::-webkit-scrollbar {
  display: none;
}

.proTeamDashCard .sender {
  align-self: flex-end;
  background-color: #4b8bff !important;
  max-width: 55%;
}

.proTeamDashCard .sender p {
  color: #fff;
}

.proTeamDashCard .receiver {
  align-self: flex-start;
  background-color: #d3e1ea !important;
  max-width: 55%;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-collapse.collapse {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    width: 100%;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .navbar-nav > li {
    margin: 0.25rem 0;
    padding: 0.25rem;
  }

  .navbar-expand-lg .navbar-toggler svg {
    color: #000;
  }

  .navbar-collapse.collapse.show {
    display: flex !important;
  }

  .navbar-collapse.collapse.hide {
    display: none !important;
  }

  .collapse.navbar-collapse {
    position: absolute;
    top: 92%;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
  }

  .navbar-brand {
    width: 18%;
  }
}

@media only screen and (max-width: 768px) {
  #articleContainer {
    column-count: 1;
    column-width: 100%;
  }

  .companyServiceInfo {
    column-count: 1;
    column-width: 100%;
  }

  #teamDashboradmainNav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  #projectDashMainNav div a:first-child {
    border-radius: 1rem 1rem 0 0;
  }

  #projectDashMainNav div a:last-child {
    border-radius: 0 0 1rem 1rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #articleContainer {
    column-count: 2;
    column-width: 47%;
    column-gap: 3%;
  }
}

/* ---------210420231738---------------- */

#jobTimeline .css-55vwbm-MuiTimeline-root,
#jobTimeline .css-1fe7e6p-MuiTimelineSeparator-root {
  flex-direction: row !important;
}

#jobTimeline .css-ha3bif-MuiTimelineItem-root {
  flex-direction: column !important;
  min-width: 15rem !important;
  margin: 0px !important;
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root {
  height: 5rem !important;
  width: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001892 !important;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root h5 {
  font-size: 1.2rem;
  color: #fff;
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root p {
  font-size: 0.5rem;
  color: #fff;
}

#jobTimeline h6 {
  font-size: 0.7rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#jobTimeline .css-8apjcs-MuiTimelineConnector-root {
  height: 0.2rem !important;
  width: 1.7rem !important;
  background-color: #dedde0 !important;
  position: absolute;
  left: -13px;
  top: 0px;
  z-index: -1;
}

#jobTimeline .css-55vwbm-MuiTimeline-root {
  overflow-x: auto !important;
}

#jobTimeline .css-1fe7e6p-MuiTimelineSeparator-root {
  position: relative !important;
  top: 20%;
}

/* -----------250420231214------------------- */

#projectTimeline .rct-dateHeader span {
  font-size: 0.7rem;
}

.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div ~ div ~ div {
  display: none;
}

#printLogo {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #bfbfbf;
}

#printLogo .logiLoginHolder img {
  width: 70%;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

#registerPage .logiLoginHolder > div {
  width: 9rem;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  position: top;
}

.projImgs {
  height: 12rem !important;
  object-fit: cover;
}

.sliderimg img {
  height: 12rem !important;
  object-fit: cover;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.selEmpTypeBox {
  position: absolute;
  right: 8%;
  top: -4%;
}

.selEmpTypeBox {
  position: absolute;
  right: 1%;
  top: -4%;
}

.AuthMain {
  padding-top: 5.2rem;
  height: 100vh;
}

.leftlinkRow {
  float: left;
  width: 60%;
  padding-left: 20px;
}

.leftColLink {
  float: left;
  width: 30%;
  margin-right: 2%;
}

.continueBtnAuth {
  position: absolute;
  bottom: 1%;
  left: 0%;
  right: 0%;
}

.dashboardSlider .slick-arrow {
  top: -28%;
}

.dashboardSlider .slick-arrow.slick-prev {
  left: initial;
  right: 10%;
}

.dashboardSlider .slick-arrow.slick-next {
  right: 0%;
}

.pLength {
  position: relative;
  right: 9.5%;
}

#dash .css-169iwlq-MuiCalendarPicker-root {
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .AuthMain {
    padding-top: 6.3rem;
    padding-bottom: 1rem;
    height: auto;
  }

  .continueBtnAuth {
    position: static;
    padding: 1rem 0;
  }
}

.text-white {
  color: #fff !important;
}

.savedSerachAct {
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}

.arrowsViewCan {
  position: absolute;
  left: 4%;
  top: 4%;
}

.candviewTBtn {
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  padding: 0 !important;
}

.candBox {
  border-bottom: 1px solid #e2e2e2;
}

.candBox:hover {
  background-color: #f8f8f8;
}

table.eduTableV {
  border-collapse: collapse;
}

.eduTableV thead th {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.eduTableV thead th:first-child {
  border-radius: 1rem 0 0 0 !important;
  border-left: 1px solid #eee;
}

.eduTableV thead th:last-child {
  border-radius: 0 1rem 0 0 !important;
  border-right: 1px solid #eee;
}

.eduTableV tbody td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom: 0;
  border-left: 0;
  font-weight: 600;
  color: #000;
}

.eduTableV body tr:last-child td:first-child {
  border-radius: 0 0 1rem 0 !important;
}

.eduTableV body tr:last-child td:last-child {
  border-radius: 0 0 0 1rem !important;
}

.empTypeCard {
  cursor: pointer;
}

.empTypeCard:hover {
  background-color: #f5af10 !important;
}

.arrowsViewCan {
  position: absolute;
  left: 4%;
  top: 4%;
}

.candviewTBtn {
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  padding: 0 !important;
}

.candBox {
  border-bottom: 1px solid #e2e2e2;
}

.candBox:hover {
  background-color: #f8f8f8;
}

table.eduTableV {
  border-collapse: collapse;
}

.eduTableV thead th {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.eduTableV thead th:first-child {
  border-radius: 1rem 0 0 0 !important;
  border-left: 1px solid #eee;
}

.eduTableV thead th:last-child {
  border-radius: 0 1rem 0 0 !important;
  border-right: 1px solid #eee;
}

.eduTableV tbody td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom: 0;
  border-left: 0;
  font-weight: 600;
  color: #000;
}

.eduTableV body tr:last-child td:first-child {
  border-radius: 0 0 1rem 0 !important;
}

.eduTableV body tr:last-child td:last-child {
  border-radius: 0 0 0 1rem !important;
}

.empTypeCard {
  cursor: pointer;
}

.empTypeCard:hover {
  background-color: #f5af10 !important;
}

.sub-li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 10px;
}

.plan-box {
  /* background-color: rgb(236, 241, 241); */
  background-color: white;
  padding: 1rem;
  padding-left: 3rem;
  position: relative;
}

.price {
  position: absolute;
  top: 3.2rem;
  left: 3.5rem;
}

.Addon-box {
  background-color: white;
  position: relative;
}

/* .Addon-price {
  position: absolute;
  top: 1rem;
  right: 1.6rem;
} */

.Addon-span {
  color: black;
  font-size: 9px;
  margin-left: 2px;
}

/* ------------110520231311---------a */

.tabButtons:hover {
  background-color: transparent !important;
}

#contactpage .MuiDataGrid-main::-webkit-scrollbar {
  height: 1px !important;
}

#contactpage .MuiDataGrid-main .MuiDataGrid-columnHeaders {
  background-color: #f5f6fa !important;
  border-radius: 0 !important;
}

#contactPageDialog .MuiDialog-container {
  align-items: flex-start !important;
  justify-content: flex-end !important;
}

#contactPageDialog .MuiDialog-container > .MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  /* height: 100vh; */
  overflow-y: auto !important;
}

#contactPageDialogAdd .MuiDialog-container {
  align-items: flex-start !important;
  justify-content: flex-end !important;
  height: 110%;
}

#contactPageDialogAdd .MuiDialog-container > .MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  /* height: 110%; */
  height: 100%;
  overflow-y: auto !important;
}

.hidePlaceholder .thumbHolder .dropAreaProjectThumb {
  width: 100%;
}

.manage-box {
  box-sizing: border-box;
  border-radius: 10px;
  width: 5rem;
  border: solid #001892;
  border-width: thin;
  padding: 5px 10px;
}

/* css for talentkind followers module 16-05-23 */

.talentsavtar {
  position: relative;
  text-align: center;
  color: white;
}

.talentsbottomrightbox {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  /* background: linear-gradient(to right, transparent 0%, black 100%); */
  background: linear-gradient(to right, transparent 0%, #03321c 100%);
  padding: 4px;
}

.talentsbottomright {
  text-align: right;
  padding-right: 10px;
}

.BtnFollowing {
  color: #005e32 !important;
  cursor: pointer;
}

.BtnFollower {
  background: #005e32 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
}

.BtnFollowingtinsti {
  color: #a91774 !important;
  cursor: pointer;
}

.BtnFollowerinsti {
  background: #a91774 !important;
  box-shadow: none !important;
}

.BtnFollowingcomp {
  color: #001892 !important;
  cursor: pointer;
}

.BtnFollowercomp {
  background: #001892 !important;
  box-shadow: none !important;
}

/* -----------170520231138------------------- */
#Remuneration .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #f2f2f2 !important;
  border-radius: 0%;
}

#Remuneration
  .css-yrdy0g-MuiDataGrid-columnHeaderRow
  .MuiDataGrid-columnHeaderTitle {
  color: #030f54 !important;
}

.text-left {
  text-align: left !important;
}

.remunerationPrintPreview table th {
  text-align: left;
  padding: 1rem;
}

#projectDurationTimeline {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

#projectDurationTimeline .MuiDialog-container {
  align-items: flex-start !important;
  height: 110%;
}

#projectDurationTimeline .MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  height: 100%;
}

#jobOfficePreview table tr th {
  text-align: start;
  padding: 0.5rem;
}

#jobOfficePreview table tr td {
  border: none !important;
  padding: 0.5rem;
}

/* ------------180520231034-------------sai */

.Payment-legend {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.AccessBox {
  background-color: #394ba6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: max-content;
}

#tableHead .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #394ba6 !important;
  color: #fff !important;
}

.RHFpayment {
  position: relative;
}

.search {
  position: absolute;
  top: -2.5rem;
  right: -13rem;
}

/* ------------------180520232001----------------pr */

#jobTimeline .css-55vwbm-MuiTimeline-root,
#jobTimeline .css-1fe7e6p-MuiTimelineSeparator-root {
  flex-direction: row !important;
}

#jobTimeline .css-ha3bif-MuiTimelineItem-root {
  flex-direction: column !important;
  min-width: 15rem !important;
  margin: 0px !important;
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root {
  height: 5rem !important;
  width: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001892 !important;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root h5 {
  font-size: 1.2rem;
  color: #fff;
}

#jobTimeline .css-eq3g4a-MuiTimelineDot-root p {
  font-size: 0.5rem;
  color: #fff;
}

#jobTimeline h6 {
  font-size: 0.7rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#jobTimeline .css-8apjcs-MuiTimelineConnector-root {
  height: 0.2rem !important;
  width: 1.7rem !important;
  background-color: #dedde0 !important;
  position: absolute;
  left: -13px;
  top: 0px;
  z-index: -1;
}

#jobTimeline .css-55vwbm-MuiTimeline-root {
  overflow-x: auto !important;
}

#jobTimeline .css-1fe7e6p-MuiTimelineSeparator-root {
  position: relative !important;
  top: 20%;
}

.menu-button {
  border: none !important;
  color: black !important;
  padding: 8px 10px !important;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: normal;
}

.countBadge {
  color: white;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 25%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.hilight {
  background-color: #000;
  width: 100%;
  font-size: 0.3rem;
  position: absolute;
  text-align: right;
  opacity: 0.3;
  color: #fff;
  bottom: 2px;
  z-index: 1;
  border-radius: 0px 0px 10px 10px;
}

/* ------------240520231016--------------------------nik-- */

.userAdvancedsearchtrend {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 90%;
  background: linear-gradient(to right, transparent 0%, #03321c 100%);
}

.userAdvancedsearchtrendtext {
  text-align: right;
  padding-right: 10px;
  color: white;
  text-transform: capitalize;
  font-weight: 600;
}

.userAdvancedsearchtrendicons {
  text-align: left;
  float: left;
  margin: 0px -10px;
}

.searchrescard {
  display: flex;
  padding-right: 10px;
}

.css-3iz97c {
  height: 250px;
}

@media screen and (max-width: 480px) {
  .searchrescard {
    display: flex;
    flex-direction: column !important;
  }
}

/* -----------------------240520231036=-----------------sai */

.Payment-legend {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.AccessBox {
  background-color: #394ba6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: max-content;
}

#tableHead .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: #394ba6 !important;
  color: #fff !important;
}

.RHFpayment {
  position: relative;
}

.search {
  position: absolute;
  top: -2.5rem;
  right: -13rem;
}

.jobactivetab {
  background-color: none;
  color: black !important;
  border-bottom: 2px solid #001892;
  padding: 8px;
  /* border-radius: 5px; */
}

.blockContent1 {
  /* background-color: #666666; */

  background-color: rgba(35, 36, 36, 0.724) !important;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}

.blockContent2 {
  background-color: rgba(102, 102, 102, 255);
  background-color: rgba(35, 36, 36, 0.724) !important;

  /* width: 46rem;
    height: 35rem; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}

@media (max-width: 575.98px) {
  .dialog {
    position: fixed;
    top: "auto";
    left: "auto";
  }
}

.dialogBox {
  background-color: white;
  border-radius: 10px;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: translate(-50%, -50%);
}

@media (max-width: 300px) {
  .creditBox {
    margin-left: 40px;
  }
}

.tableCustom2 {
  border: 1px solid #d3e1ea;
  /* width: 90%; */
  border-collapse: collapse;
}

/* ------------------------------16052023-Sai------------------------------------ */

.unpublishBox {
  border: 1px solid #d3e1ea;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: #000;
}

.addPic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #a8a8a8;
  color: white;
  font-size: 3rem;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-top: 2rem;
    margin-bottom: 2rem; */
  /* border: 1px solid black; */
  /* z-index: 1; */
  /* padding: 1rem; */
}

.addPicBtn {
  font-size: 5rem;
  right: 10px;
  top: 31px;
  left: 8px;
}

.addPicBorder {
  border: 1px solid #c5bdfd;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* padding-bottom: 10px; */
}

/* .createAccount {
  background-color: rgba(35, 36, 36, 0.424) !important;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 1111;
  width: 100%;
} */

/* ------------------------------23052023-Sai------------------------------------ */

.TeamAccessBox {
  background-color: #ffffff;
  /* color: #394ba6; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  margin-top: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: max-content;
  cursor: pointer;
}

/* -----------240520231041-------------------nik */
.userAdvancedsearchtrend {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 90%;
  background: linear-gradient(to right, transparent 0%, #03321c 100%);
  border-bottom-right-radius: 5px;
}

.userRecommendation {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, #03321c 100%);
  border-bottom-right-radius: 5px;
}

.userAdvancedsearchtrendtext {
  padding-top: 5px;
  text-align: right;
  padding-right: 10px;
  color: white;
  text-transform: capitalize;
  font-weight: 600;
}

.userAdvancedsearchtrendicons {
  text-align: left;
  float: left;
  margin: 0px -10px;
}

.searchrescard {
  display: flex;
  padding-right: 10px;
}

.css-3iz97c {
  height: 250px;
}

@media screen and (max-width: 480px) {
  .searchrescard {
    display: flex;
    flex-direction: column !important;
  }
}

.jobpostingLegendBox {
  border: 1px solid #cbcbcb;
  width: 13px;
  cursor: pointer;
  display: flex;
  height: 15px;
  align-items: center;
  justify-content: center;
}

.jobposting-fieldset {
  border-color: rgba(204, 204, 204, 0.27);
  padding: 8px;

  border-top-left-radius: 6px;
  border-radius: 4px;
}

.jobposting-legend {
  padding-right: 0.5rem;
}

/* -----------------------------2605023-Sai-------------------------------- */

/* For screens smaller than 576px (mobile devices)  */
@media (max-width: 575.98px) {
  .projectOuterThumb {
    height: 380px;
    width: 235px;
  }

  .projectOuterThumbHolder {
    height: 480px;
    width: 235px;
    margin: 0 8px;
  }

  .projectOuterThumb img {
    object-fit: cover;
    height: 380px;
  }
}

@media (max-width: 300px) {
  .projectOuterThumb {
    height: 350px;
    width: 200px;
  }

  .projectOuterThumbHolder {
    height: 450px;
    width: 200px;
    margin: 0 8px;
  }
}

@media (max-width: 575.98px) {
  .border-cards {
    border-style: dashed;
    border-width: 1px;
    width: 235px;
    border-radius: 8px;
  }
}

@media (max-width: 300px) {
  .border-cards {
    border-style: dashed;
    border-width: 1px;
    width: 200px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .dashboardSlider .slick-arrow.slick-prev {
    left: initial;
    right: 17%;
  }

  .dashboardSlider .slick-arrow {
    top: -28%;
  }
}

@media (max-width: 300px) {
  .dashboardSlider .slick-arrow.slick-prev {
    left: initial;
    right: 18%;
  }

  .dashboardSlider .slick-arrow {
    top: -18%;
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .pLength {
    position: relative;
    right: 8.5%;
  }
}

@media (min-width: 992px) and (max-width: 2000px) {
  .pLength {
    position: relative;
    right: 10.5%;
  }
}

@media (max-width: 768px) {
  .pLength {
    position: relative;
    right: 12%;
  }
}

@media (min-width: 300px) and (max-width: 390px) {
  .pLength {
    position: relative;
    right: 14%;
  }
}

*
  -----------------------------0106023-Sai--------------------------------
  */
  .jobScrollbar::-webkit-scrollbar {
  display: none;
}

.messageAreaScroll {
  height: 80.7%;
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

/* .messageAreaScroll ::-webkit-scrollbar ::-webkit-scrollbar-button {
  background: red;
  width: 10px;
  height: 2rem;
  color: red;
} */

.messageAreaBtn {
  position: absolute;
  top: 30px;
  right: 20px;
}

.proTeamDashCard .sendermsg {
  align-self: flex-end;
  background-color: #4b8bff !important;
  max-width: 55%;
}

.proTeamDashCard .sender p {
  color: #fff;
}

.proTeamDashCard .receivermsg {
  align-self: flex-start;
  background-color: #f0eeee !important;
  max-width: 55%;
}

/* ::-webkit-scrollbar {
  color: black;

  width: 10px;
  height: 2rem;
  background: red;
}
::-webkit-scrollbar-button {
  background: black;
} */
/* -----------------------------0206023-Sai-------------------------------- */

.messageAreaScroll::-webkit-scrollbar {
  width: 5px;
}

.messageAreaScroll::-webkit-scrollbar-thumb {
  background-color: #44577a;
  height: 10px;
}

.messageAreaScroll::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.jobScrollbar::-webkit-scrollbar {
  display: none;
  margin-top: 1rem;
}

#viewJobDashCal .css-169iwlq-MuiCalendarPicker-root {
  overflow-x: hidden;

  width: auto;
  max-height: 358px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.RemSearchIcon {
  position: absolute;
  top: -2.7rem;
  right: -10.5rem;
}

.sumbitBtn2 {
  margin: 0 !important;
}

.userAdvancedsearchtrendicons img {
  display: inline-block;
}

#jobOfficePreview table tr td {
  border: none !important;
  padding: 0.5rem;
  text-align: start;
}

.projectImg2 {
  height: 350px;
}

#contactPageDialog .MuiDialog-container {
  align-items: flex-start !important;
  justify-content: flex-end !important;
  /* height: 110%; */
}

#contactPageDialog .MuiDialog-container > .MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  /* height: 110%; */
  height: 100%;
  overflow-y: auto !important;
}

.jobArchivedTabs .css-aerptp-MuiButtonBase-root-MuiTab-root:not(:last-of-type) {
  margin-right: 15px !important;
}

.tabs-class .MuiTabs-flexContainer {
  justify-content: space-between !important;
  border-style: none !important;
}

.react-calendar-timeline .rct-outer {
  display: block;
  /* overflow: hidden; */
  white-space: nowrap;
}

#calenderViewBtns button {
  width: 7rem !important;
}

@media (max-width: 768px) {
  #calenderViewBtns button {
    width: 6.6rem !important;
  }
}

@media (max-width: 300px) {
  #calenderViewBtns button {
    width: 4.8rem !important;
  }
}

.calenderDays:first-child {
  border-radius: 5px !important;
}

.calenderDays:last-child {
  border-radius: 5px !important;
}

.calenderDays {
  width: 75px !important;
  border: none !important;
  display: inline-block;
  margin-right: -5px !important;
  font-weight: normal !important;
  /* border-radius: 0 !important; */
  border-radius: 5px !important;
}

.Payment-fieldset {
  /* border-color: #cccccc; */
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 0.5rem;
}

.fc-daygrid-day .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
}

.fc-daygrid-event-harness.fc-daygrid-event-harness-abs {
  /* top: -3rem !important; */
  left: -1rem;
}

.fc-daygrid-day-top {
  position: relative;
  z-index: 333;
}

.css-1hxsw79 .fc .fc-event .fc-event-main {
  display: block !important;
  background-color: transparent !important;
}

.css-1hxsw79 .fc .fc-event .fc-event-main:before {
  border: 0 !important;
}

.css-1hxsw79 .fc .fc-event .fc-event-main-frame {
  filter: none !important;
  overflow: hidden !important;
  /* margin-top: 15px; */
}

.css-1hxsw79 .fc .fc-event .fc-event-main:after {
  background-color: transparent !important;
}

.fc-timegrid-event-harness {
  position: absolute;
  margin-top: -7px;
  margin-bottom: 5px;
}

#custom-calendar .dateArrowHolder {
  display: block !important;
}

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  font-size: 20px !important;
  padding: 4px;
  /* color: green !important; */
}

.custom-date {
  color: white !important;
  font-size: 22px !important;
  font-weight: 500;
}

.proTeamDashCard .senderName {
  align-self: flex-end;
  max-width: 55%;
}

.proTeamDashCard .receiverName {
  align-self: flex-start;
  max-width: 55%;
}

a.fc-timegrid-event[style="background-color: blue;"] {
  border-color: transparent;
  min-height: 3rem;
  background-color: transparent;
}

.fc .fc-timegrid-event-harness {
  bottom: 700px;
  left: 2px !important;
  right: 2px !important;
  margin-top: 0 !important;
}

/* .rct-items div.rct-item {
  background: #44577a !important;
  border-color: #44577a !important;
} */

.timelinecolor .rct-items div.rct-item {
  background: #d3e1ea !important;
}

/* .TimelineStyles .dateArrowHolder {
  display: block !important;
} */

#teamDashboardTimeline .react-calendar-timeline .rct-header-root {
  background-color: #fff !important;
  min-height: 5rem;
}

@media (min-width: 1300px) {
  .pLength {
    position: relative;
    right: 7.5%;
  }
}

@media (max-width: 768px) {
  #banner .container-fluid .slide {
    height: 30vh;
    /* min-height: 50vh; */
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 30vh;
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  #banner .container-fluid .slide {
    height: 50vh;
    /* min-height: 50vh; */
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 50vh;
    width: 100%;
  }
}

@media (min-width: 1300px) and (max-width: 1800px) {
  #banner .container-fluid .slide {
    height: 105vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 105vh;
    width: 100%;
  }
}

@media (min-width: 1800px) {
  #banner .container-fluid .slide {
    height: 150vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 150vh;
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .pLength {
    position: relative;
    right: 11%;
  }
}

@media (min-width: 1300px) {
  .pLength {
    position: relative;
    right: 9.5%;
  }
}

@media (min-width: 1650px) {
  .pLength {
    position: relative;
    right: 7.8%;
  }
}

#projectSlider .slick-arrow.slick-next {
  right: -10px !important;
}

#projectSlider .slick-arrow.slick-prev {
  left: -10px !important;
}

#bannerSlider .slick-arrow.slick-next {
  right: 15% !important;
}

#bannerSlider .slick-arrow.slick-prev {
  left: 15% !important;
  z-index: 2;
}

#bannerSlider .slick-dots {
  position: absolute;
  bottom: 10%;
  text-align: end;
  padding-right: 10%;
}

#bannerSlider .slick-dots li button:before {
  font-family: "slick";
  font-size: 17px;
}

#bannerSlider .slick-prev,
#bannerSlider .slick-next {
  width: 87px;
  height: 93px;
}

@media (max-width: 768px) {
  #bannerSlider .slick-dots li button:before {
    font-family: "slick";
    font-size: 12px;
  }

  #bannerSlider .slick-prev,
  #bannerSlider .slick-next {
    width: 50px;
    height: 60px;
  }
}

#jobSlider .slick-arrow.slick-next {
  right: -14px !important;
}

#jobSlider .slick-arrow.slick-prev {
  left: -14px !important;
}

@media (min-width: 950px) {
  #jobSlider .slick-arrow.slick-next {
    right: -25px !important;
  }

  #jobSlider .slick-arrow.slick-prev {
    left: -25px !important;
  }
}

#bannerSlider .slick-slide img {
  width: 100%;
  max-height: 50rem;
  padding: 0;
}

@media (min-width: 900px) and (max-width: 1300px) {
  #banner .container-fluid .slide {
    height: 70vh;
    /* min-height: 50vh; */
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 70vh;
    width: 100%;
  }
}

@media (min-width: 1300px) and (max-width: 1800px) {
  #banner .container-fluid .slide {
    height: 60vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 60vh;
    width: 100%;
  }
}

@media (min-width: 1800px) {
  #banner .container-fluid .slide {
    height: 80vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #banner .container-fluid {
    height: 80vh;
    width: 100%;
  }
}

.loginInputHolderR p {
  position: absolute;
  left: 0;
  bottom: -39px;
}

.loginInputHolderR {
  position: relative !important;
  margin-bottom: 30px !important;
}

.leftResetColLink {
  float: left;
  padding: 11px;
}

.loginInputHolders p {
  position: absolute;
  left: 0;
  bottom: -36px;
}

.border-cards-photos {
  width: 305px;
  /* height: 135px !important; */
  height: 95px !important;
  border-radius: 8px !important;
}

.shadow-ss {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24) !important;
}

/* ------------------------------------24-08-23 Slider------------------------------ */
/* #slide1 {
  background-image: url(./images/Sliderimg1.jpg);
}
#slide2 {
  background-image: url(./images/Sliderimg2.jpg);
}
#slide3 {
  background-image: url(./images/Sliderimg3.jpg);
} */

.loginSwiper {
  height: 100vh;
}

.slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#RegContainer .slick-arrow {
  z-index: 2;
}

/* #RegContainer .slick-next {
  right: 1.5rem !important;
} */

/* #RegContainer .slick-prev {
  left: 1.5rem !important;
} */

#RegContainer .slick-list {
  height: 100vh;
}

/* #overlay {
  width: 90%;
  position: absolute;
  top: 14%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
} */
#overlay {
  width: 90%;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  min-height: 100vh;
}

.registerlistbox {
  background-color: rgba(0, 24, 146, 80%);
  width: fit-content;
  border-radius: 10px;
  padding: 5rem 1.1rem 2rem 1.1rem;
}

.ReglistHeight > span {
  line-height: 1;
}

#registerPage .logiLoginHolder > div {
  width: 11rem;
}

/* ---------------------------footer--------------------- */
#overlay footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#overlay footer #footerMenus ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#overlay footer #footerMenus ul li a {
  text-decoration: none;
}

#overlay footer #footerMenus ul li a,
#overlay footer #footerMenus ul li {
  color: #001892;
}

#overlay footer #footerMenus ul li a:hover,
#overlay footer #footerMenus ul li:hover {
  color: #ffc300;
}

#overlay footer #footerMenus ul li {
  margin: 0.15rem;
  padding: 0.15rem;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  #footerMenus ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
  }

  /* #footerMenus li {
    flex: 1 0 49%;
    margin-bottom: 5px;
  } */
  #overlay footer #footerMenus ul li {
    margin: 0;
  }

  #footerMenus ul li:first-child {
    width: 100%;
  }

  #footerMenus ul li {
    font-size: 12px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1220px) {
  #overlay footer {
    flex-direction: column;
    align-items: flex-start;
  }

  #overlay footer #footerMenus ul {
    /* flex-wrap: wrap-reverse; */
    flex-wrap: wrap;
  }

  #overlay footer #footerMenus ul li {
    margin: 0.15rem;
    padding: 0.15rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  #RegContainer #RegContainerBox {
    height: 100vh;
  }

  #RegContainer .slick-list {
    height: 250vh;
  }

  #RegContainer .slide {
    height: 250vh;
  }

  #RegContainer #overlay {
    min-height: 250vh !important;
  }

  /* #RegContainer .slick-next {
    right: 3px !important;
  } */

  /* #RegContainer .slick-prev {
    left: 3px !important;
  } */
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  /* #RegContainer .slick-next {
    right: 1rem !important;
  } */

  /* #RegContainer .slick-prev {
    left: 1rem !important;
  } */
  .logiLoginHolder .MuiBox-root {
    margin: 0;
  }
}

#RegContainer .slick-next {
  display: none !important;
}

#RegContainer .slick-prev {
  display: none !important;
}

/* -----------------------------------250823------------------- */
#slide1 {
  background-image: url(./images/Sliderimg01.jpg);
}

#slide2 {
  background-image: url(./images/Sliderimg02.jpg);
}

#slide3 {
  background-image: url(./images/Sliderimg03.jpg);
}

#slide4 {
  background-image: url(./images/Sliderimg04.jpg);
}

.loginInputHolderR p {
  position: absolute;
  left: 0;
  top: 46px;
}

.Shadowss {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.loginInputHolders p {
  position: absolute;
  left: 0;
  /* bottom: -36px; */
  top: 46px;
}

#LoginContainer .slick-arrow {
  z-index: 2;
}

#LoginContainer .slick-next {
  display: none !important;
}

#LoginContainer .slick-prev {
  display: none !important;
}

#LoginContainerBox {
  padding-bottom: 7.2rem !important;
}

#LoginContainer .slick-list {
  height: 100vh;
}

@media (max-width: 768px) {
  #LoginContainer #LoginContainerBox {
    height: 100vh;
  }

  #LoginContainer .slick-list {
    height: calc(100vh + 10rem);
  }

  #LoginContainer .slide {
    height: calc(100vh + 10rem);
  }

  #LoginContainer #overlay {
    min-height: calc(100vh + 10rem) !important;
  }

  .ResetPassCard {
    padding: 0.5rem 1.7rem 1rem 1.7rem !important;
  }
}

.set-proj-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  padding-left: 16px;
}

.projectOuterThumb img {
  object-fit: cover;
  height: 300px;
}

.projectOuterThumb {
  height: 300px;
  width: 212px;
}

.projectOuterThumbHolder {
  height: 400px;
  width: 212px;
  margin: 0 8px;
}

@media (max-width: 768px) {
  .projectOuterThumb {
    width: 230px;
  }

  .projectOuterThumbHolder {
    width: 230px;
  }
}

@media (min-width: 1535) and (max-width: 2000) {
  .projectOuterThumb {
    width: 218px;
  }

  .projectOuterThumbHolder {
    width: 218px;
  }
}

@media (min-width: 2000) {
  .projectOuterThumb {
    width: 220px;
  }

  .projectOuterThumbHolder {
    width: 220px;
  }
}

.navbar-nav .nav-item:hover .nav-link {
  border-bottom: 3px solid rgb(0, 110, 255);
}
.miltiNavBar .nav-item:hover .nav-link {
  border-bottom: none !important;
}

.navbar-nav .nav-item .nav-link {
  transition: color 0.5s ease-in-out;
}

.sliderValueColor .MuiSlider-valueLabel {
  background-color: #001892 !important;
}

.sliderImageBoxProjectJob img {
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 300px;
}

.textfieldcolor .MuiInputBase-input {
  background-color: #f2f2f2;
}
.textfieldcolorName .MuiInputBase-input {
  background-color: #f2f2f2;
  text-transform: capitalize;
}
.firstLetter::placeholder {
  text-transform: capitalize;
}

.projectImgHolder .thumbHolder {
  position: relative;
  height: 350px;
  width: auto;
}

.profileType {
  justify-content: left !important;
  border: 1px solid #c4cdd5;
  box-shadow: none !important;
  width: 440px;
}

@media only screen and (max-width: 768px) {
  .profileType {
    width: 100%;
  }
}

.manage-box {
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 5rem;
  border: 1px solid #001892;
  /* border-width: thin; */
  padding: 5px 10px;
}

.hidePlaceholder .thumbHolder .dropAreaProjectThumb {
  height: 19rem;
}

.projectOuterThumbHolder {
  margin: 0px;
}

.searchButton {
  min-width: 50px !important;
}

.ActiveJobStataus {
  color: green !important;
}

.staffSearchButton {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  height: 52px !important;
}

.WebsiteTextField input {
  height: 5px;
}

.loginInputHolder p {
  position: absolute;
  left: 0;
  bottom: -20px;
  top: 44px;
}

.myprojectmessage {
  border-radius: 8px !important;
  border: 1px solid #ccc;
}

.myprojectmessage::before {
  border: none !important;
}

.myprojectmessage::after {
  border: none !important;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.hidePlaceProjectImg {
  height: 100%;
}

.thumbHolder {
  height: 100%;
}

.dropAreaProjectThumb {
  height: 100%;
}

.selectClass {
  padding: 15px 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 600;
  margin-left: 0px;
  margin-top: 5px;
}

.Border_Radius {
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
}

.activeViewJobTap {
  background-color: transparent !important;
  padding: 8px 0px !important;
  /* padding-bottom: 8px !important; */
  color: #000 !important;
}

.candviewTBtn {
  color: grey !important;
  /* padding-bottom: 8px !important; */
  padding: 8px 0px !important;
}

.eduTableV thead th {
  font-weight: 400;
  font-size: 0.9rem;
  padding: 0.7rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: left;
  color: #000;
}

.eduTableV tbody td {
  color: #000;
  font-size: 15px;
}

.AvatarImg img {
  object-fit: inherit;
}

.ProfileIntro {
  column-count: 2;
  column-gap: 3%;
  column-width: 47%;
}

@media (max-width: 768px) {
  .ProfileIntro {
    column-count: 1;
    column-width: 100%;
  }
}

.indicator button {
  width: 25%;
  /* border: 1px solid #d3e1ea; */
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  background: #d3e1ea;
  border-right: 1px solid #d7cfcf;
}

.indicator {
  background-color: #d3e1ea !important;
}

.indicator .MuiTabs-indicator {
  background-color: transparent !important;
}

.awardBox {
  border: 2px solid rgb(201, 212, 207);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
  border-radius: 8px;
  margin: 1.5% 1.5% 0 0;
}

.cardShadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24) !important;
}

.tableCustom1 {
  border: 1px solid #ccc;
}

.tableCustom1 th,
td {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.JobseekBtn {
  position: absolute !important;
  right: 2px;
  bottom: 1px;
  height: 38px;
}

.JobseekTextfield input {
  padding: 10px !important;
}

@media (max-width: 768px) {
  .hidePlaceProjectImg {
    height: 13rem;
  }
}

.Border_Radius a {
  color: blue;
}

.indicator button {
  width: 25%;
  /* border: 1px solid #d3e1ea; */
  margin-right: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  background: #d3e1ea;
  border-right: 1px solid #d7cfcf;
}

.indicator {
  background-color: #d3e1ea !important;
}

.indicator .MuiTabs-indicator {
  background-color: transparent !important;
}

.awardBox {
  border: 2px solid rgb(201, 212, 207);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;
  border-radius: 8px;
  margin: 1.5% 1.5% 0 0;
}

.cardShadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24) !important;
}

.tableCustom1 {
  border: 1px solid #ccc;
}

.tableCustom1 th,
td {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.JobseekBtn {
  position: absolute !important;
  right: 2px;
  bottom: 1px;
  height: 38px;
}

.JobseekTextfield input {
  padding: 10px !important;
}

@media (max-width: 768px) {
  .hidePlaceProjectImg {
    height: 13rem;
  }
}

.Border_Radius a {
  color: blue;
}

@media (max-width: 768px) {
  #LoginContainer #LoginContainerBox {
    height: 100vh;
  }

  #LoginContainer .slick-list {
    height: calc(100vh + 15rem);
  }

  #LoginContainer .slide {
    height: calc(100vh + 15rem);
  }

  #LoginContainer #overlay {
    min-height: calc(100vh + 15rem) !important;
  }

  .ResetPassCard {
    padding: 0.5rem 1.7rem 1rem 1.7rem !important;
  }
}

.StickyFooter {
  position: fixed !important;
  bottom: 0;
  width: 100%;
}

#jobTimeline ul {
  flex-direction: row !important;
  overflow-x: auto !important;
}

#jobTimeline li {
  flex-direction: column !important;
  min-width: 15rem !important;
  width: 16rem !important;
  margin: 0px !important;
}

#jobTimeline .MuiTimelineDot-root {
  height: 5rem !important;
  width: 5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #001892 !important;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

#jobTimeline .MuiTimelineDot-root h5 {
  font-size: 1.2rem;
  color: #fff;
}

#jobTimeline .MuiTimelineDot-root p {
  font-size: 0.5rem;
  color: #fff;
}

#jobTimeline h6 {
  font-size: 0.7rem;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#jobTimeline .MuiTimelineConnector-root {
  height: 0.2rem !important;
  width: 1.7rem !important;
  background-color: #dedde0 !important;
  position: absolute;
  left: -13px;
  top: 0px;
  z-index: -1;
}

/* #jobTimeline .css-55vwbm-MuiTimeline-root {
  overflow-x: auto !important;
} */

#jobTimeline .MuiTimelineSeparator-root {
  flex-direction: row !important;
  position: relative !important;
  top: 18%;
}

@media (max-width: 768px) {
  #RegContainer #RegContainerBox {
    height: 100vh;
  }

  #RegContainer .slick-list {
    height: calc(250vh + 15rem);
  }

  #RegContainer .slide {
    height: calc(250vh + 15rem);
  }

  #RegContainer #overlay {
    min-height: calc(250vh + 15rem) !important;
  }
}

#RegContainer #RegContainerBox {
  min-height: 800px !important;
}

#RegContainer .slick-list {
  min-height: 800px !important;
}

#RegContainer .slide {
  min-height: 800px !important;
}

#RegContainer #overlay {
  min-height: 800px !important;
}

.Z_index {
  z-index: 2;
}

/* ------------------------------------070923-------------------- */
.textfieldcolor .MuiInputBase-input {
  background-color: #f2f2f2;
  border-radius: 8px;
}

.loginMessage {
  height: auto !important;
}

#ResetConfirmContainer .slick-arrow {
  z-index: 2;
}

#ResetConfirmContainer .slick-next {
  display: none !important;
}

#ResetConfirmContainer .slick-prev {
  display: none !important;
}

#ResetConfirmContainerBox {
  padding-bottom: 7.2rem !important;
}

#ResetConfirmContainer .slick-list {
  height: 100vh;
}

@media (max-width: 768px) {
  #ResetConfirmContainer #ResetConfirmContainerBox {
    height: 100vh;
  }

  #ResetConfirmContainer .slick-list {
    height: calc(100vh + 20rem);
  }

  #ResetConfirmContainer .slide {
    height: calc(100vh + 20rem);
  }

  #ResetConfirmContainer #overlay {
    min-height: calc(100vh + 20rem) !important;
  }

  .ResetPassCard {
    padding: 0.5rem 1.7rem 1rem 1.7rem !important;
  }
}

#projectSlider .slick-arrow.slick-next {
  right: -25px !important;
}

#projectSlider .slick-arrow.slick-prev {
  left: -25px !important;
}

.Radius5 {
  border-radius: 5px !important;
}

.Radius8 {
  border-radius: 8px !important;
}

#companyJobsTable {
  /* border: 1px solid #ccc !important; */
  border: none !important;
}

#companyJobsTable th {
  border: 1px solid #eee !important;
  /* border: none !important; */
}

#companyJobsTable tr {
  border: none !important;
  /* border: 1px solid #eee !important; */
}

#companyJobsTable td {
  /* border: none !important; */
  border: 1px solid #eee !important;
}

#companyJobsTable:last-child td {
  border-bottom: none !important;
  border-right: none !important;
}

#companyJobsTable:first-child td {
  border-left: none !important;
}

#companyJobsTable:last-child th {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

#companyJobsTable thead th {
  padding: 0px !important;
}

/* .NavbarAvatar img {
  object-fit: contain !important;
} */

/* --------------110923------------ */
/* .Jobposting :last-child th {
  border-bottom: none !important;
}
.Jobposting :last-child td {
  border-bottom: none !important;
}
.Jobposting tr {
  border: none !important;
}
.Jobposting tr th:first-child {
  border-left: none;
}
.Jobposting tr td:first-child {
  border-left: none !important; 
} */

.Jobposting tr th:first-child,
.Jobposting tr td:first-child {
  border-left: none;
}

.Jobposting tr,
.Jobposting :last-child th,
.Jobposting :last-child td {
  border: none !important;
}

.borderLeft {
  border-left: 1px solid #ccc !important;
}

.linkCssChanges:last-child td {
  border-bottom: none;
}

.projectJobBorder tr th:first-child,
.projectJobBorder tr td:first-child {
  border-left: none;
}

.projectJobBorder a {
  padding: 0px;
}

.showOnWebsite {
  float: right;
  margin-bottom: 10px;
  margin-right: 10px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.ViewMoreIndustries {
  float: right;
  position: absolute;
  bottom: 2px;
  right: 2px;
  cursor: pointer;
}

.WebsiteLineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.TeamDashboardArrow {
  position: absolute !important;
  right: 4%;
  top: 38%;
}

/* ---------120923------ */
.TeamDashTable tr {
  border-top: 0.5px solid #d4d4d4;
  border-bottom: 0.5px solid #d4d4d4;
}

.TeamDashTable th {
  background-color: #fff !important;
  color: #000;
  padding: 5px;
}

.boxcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Teamdashscroll::-webkit-scrollbar {
  width: 5px;
}

.Teamdashscroll::-webkit-scrollbar-thumb {
  background-color: #44577a;
  height: 10px;
}

.Teamdashscroll::-webkit-scrollbar-track {
  background-color: #d9dde4;
}

.PaymentSearchIcon {
  position: absolute;
  top: -2.8rem;
  right: -12.5rem;
}

.TeamDashTable tr {
  border-top: 0.5px solid #d4d4d4;
  border-bottom: 0.5px solid #d4d4d4;
}

.teamPaymentTable th {
  background-color: #f2f2f2 !important;
  color: #030f54;
  padding: 7px;
  border-radius: 0px !important;
}

.paymentSettingsPan fieldset {
  width: 5rem;
}

/* .paymentSettingsPan input {
  width: 5rem;
} */

/* ----------13-09-23----------- */
@media (max-width: 768px) {
  .margin0 {
    margin: 0 !important;
  }
}

.History {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid #d3e1ea;
  padding-bottom: 8px;
  margin-bottom: 1rem;
}

/* ---------140923--------- */

.PreviousHistory {
  margin: 2px;
  margin-bottom: 16px;
  min-height: 6rem;
  max-height: 13rem;
  overflow-y: auto;
}

.mainavigation {
  display: flex;
  justify-content: flex-end;
  align-self: center;
}

@media (max-width: 768px) {
  .mainavigation {
    display: block;
  }
}

/* --------------------15-09-23---------- */
.officePreview {
  max-height: 5rem;
  overflow: auto;
}

@media (max-width: 1200px) {
  .Teamdashscroll::-webkit-scrollbar {
    width: 8px;
  }
}

.SkeletonColourb {
  background-color: #e4e4e4 !important;
}

/* PowerText.css - Khushbu 18-09-2023 */
.power-text {
  font-size: 16px;
  /* Adjust the font size as needed */
}

.superscript {
  font-size: 60%;
  /* Adjust the font size of the exponent */
  vertical-align: top;
  /* Align the exponent text as superscript */
  margin-left: 2px;
  /* Add some spacing between base and exponent */
}

@media (max-width: 1200px) {
  .messageAreaScroll::-webkit-scrollbar {
    width: 8px;
  }
}

/* @media (max-width: 768px) {
  .fc .fc-popover {
    left: 15px !important;
    top: 250px !important;
  }
} */

.blueConfirmBtn {
  background-color: #030f54 !important;
  color: #fff !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: 100 !important;
}

.blueConfirmBtn :hover {
  background-color: #030f54 !important;
}

/* ----------21-09-23--------------- */
.deleteAccount {
  border-radius: 1rem !important;
}

/* -------------270923--------------------- */
.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 768px) {
  #projectSlider {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .rightProfileAvatar {
    width: 50px !important;
    height: 50px !important;
  }
}

.searchFilterTextfield input {
  height: 12px !important;
  min-width: 15rem !important;
}

.searchRecommendationTextfield input {
  height: 12px !important;
  min-width: 8rem !important;
}

.searchFilterTextfieldSearch input {
  height: 12px !important;
  /* min-width: 15rem !important; */
}

.searchFilterTextfieldSave input {
  height: 12px !important;
  min-width: 13rem !important;
}

.searchFilterTextfieldQuick input {
  height: 12px !important;
  /* min-width: 13rem !important; */
}

.MuiDataGrid-pagination {
  visibility: visible !important;
}

.rightProfileAvatar {
  width: 60px !important;
  height: 60px !important;
}

@media (max-width: 768px) {
  .rightProfileAvatar {
    width: 50px !important;
    height: 50px !important;
  }
}

.ProDashImg .wrapper {
  height: 100% !important;
}

/* ------------------------------------------------------- */

.contactDataGrid .MuiDataGrid-columnSeparator {
  display: none !important;
}

.contactDataGrid .MuiDataGrid-cell {
  border-bottom: none !important;
}

.contactDataGrid .MuiDataGrid-virtualScroller {
  padding-bottom: 4rem !important;
}

.contactDataGrid .MuiDataGrid-row {
  /* margin-bottom: 10px !important; */
  min-height: 58px !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.displayCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.WebProEditBtn {
  position: absolute;
  top: 8px;
  right: -5px;
}

.detailedDataWidth .MuiDataGrid-columnHeader,
.detailedDataWidth .MuiDataGrid-cell {
  /* width: 155px !important;
  min-width: 155px !important; */
  color: #8b8b8b !important;
}

.detailedDataWidth .MuiDataGrid-columnHeader {
  color: #282828 !important;
}

/* @media only screen and (min-width: 1400px) and (max-width: 1800px) { */
@media (max-width: 1800px) {
  /* .detailedDataWidth .MuiDataGrid-columnHeader,
  .detailedDataWidth .MuiDataGrid-cell {
   
    width: 110px !important;
    min-width: 110px !important;
  } */
  /* .detailedDataWidth .MuiDataGrid-virtualScroller {
    width: fit-content !important;
  } */
  .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
    /* width: fit-content !important; */
    width: auto !important;
  }

  .MuiDataGrid-virtualScrollerContent .css-0 {
    width: auto !important;
  }

  .detailedDataWidth .MuiDataGrid-virtualScrollerContent .css-0 {
    width: auto !important;
  }
}

/* .detailedDataWidth .MuiDataGrid-cell {
  width: auto !important;
  min-width: none !important;
} */

.detailedDataWidth .MuiDataGrid-cell {
  border-bottom: none !important;
}

.detailedDataWidth .MuiDataGrid-columnSeparator {
  display: none !important;
}

.detailedDataWidth .MuiDataGrid-virtualScroller {
  padding-bottom: 4rem !important;
}

.detailedDataWidth .MuiDataGrid-row {
  /* margin-bottom: 10px !important; */
  min-height: 58px !important;
}

.detailedDataWidth .MuiDataGrid-cell {
  min-height: 40px !important;
  max-height: 40px !important;
  height: 40px;
  justify-content: flex-start !important;
}

.detailedDataWidth .Mui-selected {
  background-color: transparent !important;
}

.detailedDataWidth .MuiDataGrid-row--editing {
  box-shadow: none !important;
}

.detailedDataWidth .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-start !important;
}

.detailedDataWidth
  .MuiDataGrid-row--editing
  .MuiDataGrid-cell
  .MuiDataGrid-cell--textCente {
  border: 1px solid #ccc !important;
}

.MuiDataGrid-row--editing .MuiDataGrid-cell.MuiDataGrid-cell--textCenter {
  border: 1px solid #ccc;
  margin: 0 2px;
  border-radius: 5px;
  border-bottom: 1px solid #ccc !important;
}

.detailedDataWidth
  .MuiDataGrid-cell.MuiDataGrid-cell--textCenter[data-colindex="0"] {
  border: none !important;
}

/* ---------- */
.detailedDataWidth .MuiDataGrid-columnHeader:focus-within,
.detailedDataWidth .MuiDataGrid-cell:focus-within {
  outline: 1px solid #ccc !important;
  /* outline: none !important; */
}

/* .MuiDataGrid-sortIcon {
  opacity: 1 !important;
} */

/* Display the sort icon without hover in MUI Data Grid Pro */
.css-1xmbyjz-MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)
  .MuiDataGrid-sortIcon {
  visibility: visible;
  pointer-events: none;
  /* Disable pointer events so it's not interactive */
}

.ActionBtnShadow .MuiPaper-root {
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 0 0 rgba(0, 0, 0, 0.23) !important; */
  box-shadow: 0 0px 11px rgba(0, 0, 0, 0.16), 0 0 0 rgba(3, 1, 0, 0.23) !important;
}

#BugdetDialog .MuiDialog-container {
  align-items: flex-start !important;
  justify-content: flex-end !important;
}

#BugdetDialog .MuiDialog-container > .MuiPaper-root {
  margin: 0 !important;
  border-radius: 0 !important;
  height: 100vh;
  overflow-y: auto !important;
}

/* ------------------------------------- */

/* .detailedDataWidth .MuiDataGrid-cell,
.MuiDataGrid-columnsContainer {
  border: 1px solid #ccc !important;
  margin-right: 4px !important;
  border-radius: 6px !important;
} */
/* .detailedDataWidth .MuiDataGrid-columnHeader {
  margin-right: 4px !important;
} */

.detailedDataWidth .MuiDataGrid-virtualScroller {
  overflow-y: auto !important;
  margin-top: 7rem !important;
}

.AddRowForm .MuiInputBase-root {
  height: 40px !important;
  border-radius: 5px !important;
}

.AddRowForm .MuiFormLabel-root {
  top: -6px !important;
}

.AddRowForm .Mui-focused {
  top: 0px !important;
}

.BudgetRole {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 150px; */
  /* margin-right: 10px !important; */
  cursor: pointer;
  color: #919eab;
}

@media (max-width: 992px) {
  .BudgetRole {
    white-space: wrap;
    word-break: break-word;
  }
}

/* .BudgetRole:hover {
  overflow: visible;
} */

/* .BudgetTotal {
  width: 110px !important;
} */
.BudgetTotal .MuiInputBase-root {
  background-color: #eee !important;
}

.firstLetter::first-letter {
  text-transform: uppercase;
}

.BudgetAutoFocus input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: #fff !important;
}

.BudgetBorder fieldset {
  border: none !important;
}

.AddRowForm,
.BudgetEditBorder {
  text-transform: capitalize;
}

.BudgetBorder .MuiInputBase-input::first-letter {
  text-transform: uppercase !important;
}

.BudgetBorder .MuiInputBase-input {
  color: #919eab !important;
}

.BudgetBorder .MuiSvgIcon-root {
  display: none !important;
}

.redStatus .MuiInputBase-input {
  color: red;
}

.redStatus .Mui-disabled {
  color: red;
  -webkit-text-fill-color: red !important;
}

.grayStatus .MuiInputBase-input {
  color: #919eab;
}

.grayStatus .Mui-disabled {
  color: #919eab;
  -webkit-text-fill-color: #919eab !important;
}

.greenStatus .MuiInputBase-input {
  color: green !important;
}

.greenStatus .Mui-disabled {
  color: green !important;
  -webkit-text-fill-color: green !important;
}

.redStatus {
  color: red !important;
}

.grayStatus {
  color: #000 !important;
}

.greenStatus {
  color: green !important;
}

.BudgetAddBtn {
  height: 25px;
  width: 25px;
  border-radius: 3px !important;
  padding: 0px !important;
  margin-top: 6px !important;
  background-color: #001892 !important;
}

.BudgetBoderBtm {
  /* border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  padding-left: 8px; */
  border-top: 1px solid #ccc;
  /* padding-top: 16px; */
}

/* .BudgetAutoFocus legend {
  height: 2px !important;
} */

.redcolorInchageQ.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.IconHover.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.staffSearchButtonS {
  position: absolute !important;
  right: -50px !important;
  top: 0 !important;
  height: 52px !important;
  /* margin: 15px !important; */
}

.castDetails .MuiTypography-root span {
  /* font-size: 10px; */
  font-weight: 400 !important;
  display: inline-block;
}

.castDetails .MuiTypography-root span:first-letter {
  text-transform: uppercase;
}

.castDetails h6 {
  word-break: break-all;
}

.ProjectVideoPlayer {
  /* width: fit-content !important; */
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.PlayBtn {
  position: absolute;
  top: 35%;
  left: 44%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.locationLink a {
  text-decoration: none;
}

.locationoutline .MuiInputBase-root {
  /* padding-bottom: 10px !important; */
  border-radius: 10px !important;
  background-color: #fff !important;
}
.locationoutline input {
  /* padding-bottom: 10px !important; */
  height: 14px;
  border-radius: 10px !important;
  background-color: #fff !important;
}

.BudgetEditBorder fieldset {
  border-color: #000 !important;
}

.leftPanelList span {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.noDataMEA {
  height: 30rem;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.ToolIcons {
  padding: 5px !important;
}

.userAdvancedsearchtrend2 {
  background: linear-gradient(to right, transparent 0%, #03321c 100%);
  border-radius: 0px !important;
}

.jobPreviewBox {
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #000;
  border-radius: 0.5rem;
}

.transform {
  text-transform: capitalize !important;
}

.TableFirstLetter td::first-letter {
  text-transform: uppercase;
}

.profileLink {
  color: #000;
  text-decoration: none;
}

.firstLetters {
  border-bottom: 1px solid #ccc !important;
  padding: 0.3rem !important;
  padding-left: 20px !important;
}

.firstLetterLeft {
  border-left: 1px solid #ccc;
  border-bottom-left-radius: none !important;
}

.firstLetterRight {
  border-right: 1px solid #ccc;
  border-bottom-right-radius: none !important;
}

.firstLetters::first-letter {
  text-transform: uppercase;
}

.SelectIncharge .css-13cymwt-control {
  border-color: red !important;
}

/* .SelectIncharge .css-13cymwt-control:hover {
  border-color: red !important;
} */

.BudgetInchageAddBtn {
  height: 25px;
  width: 25px;
  border-radius: 15px !important;
  padding: 0px !important;
  margin-top: 6px !important;
  background-color: #001892 !important;
}

.inviteTextfield input {
  height: 10px;
}

.image_container {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
  /* 5:4 aspect ratio (4 / 5 * 100) */
  overflow: hidden;
  border-radius: 5px;
}

.fixed_ratio_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
}

.fixed_ratio_imageStaff {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 26px;
  object-fit: cover;
  /* Maintain aspect ratio while covering the container */
}

.tooltipContainer {
  /* Add styles for the container to limit width and show ellipsis */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Add any other necessary styles */
}

.tooltipContainer:hover .MuiTooltip-tooltip {
  /* Show tooltip only on hover when text is truncated */
  display: block !important;
}

.DepartRoleType .MuiButtonBase-root {
  margin-right: 20px !important;
}

.projectOuterThumbHolderA {
  height: 335px;
  width: 212px;
  margin: 0 8px;
}

.WebsiteLineClampDesc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Ensure tooltip works on truncated text */
  white-space: normal;
}

.PaymentHistoryTable th {
  background-color: #a6add3 !important;
  color: #030f54;
  /* padding: 7px; */
  padding: 7px 16px;
  border-radius: 0px !important;
  font-size: 15px !important;
  line-height: 1.8rem !important;
}

/* .tablerow2 {
  padding-left: 10px !important;
} */
.PaymentHistoryTable td {
  font-size: 15px !important;
  font-weight: 600;
}

.PaymentHistoryTable .MuiTableCell-root {
  box-shadow: none !important;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30rem;
  font-size: 18px;
  font-weight: 500;
}

.paymentSuccIcon {
  background-color: green;
  border-radius: 50%;
}

.paymentFailIcon {
  background-color: red;
  border-radius: 50%;
}

.SubscriptionPlanText {
  writing-mode: tb;
  transform: scale(-1);
  font-size: 24px !important;
}

.subsciptionToggle {
  padding: 4px !important;
  border: 0.5px solid #eee !important;
  /* box-shadow: none; */
  width: fit-content;
  border-radius: 8px;
}

.subsciptionToggle .MuiButtonBase-root {
  padding: 5px 40px;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.upperCase {
  text-transform: uppercase !important;
}

.subscriptionArrow {
  position: absolute;
  top: 46%;
  right: -17px;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

/* .theme5Background {
  background: repeating-linear-gradient(
    to bottom,
    #503760,
    #503760 1px, 
    #424261 1px, 
    #424261 4px 
  );
} */

.theme5Background {
  background: linear-gradient(to bottom, #091856, #3939ac) !important;
  /* border-bottom: 1px solid #eee; */
}

/* Add this in your CSS file */
.boxWithCuttingEffect {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.boxWithCuttingEffect::before,
.boxWithCuttingEffect::after {
  content: "";
  position: absolute;
  bottom: -9px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
}

.boxWithCuttingEffect::before {
  left: -23px;
  /* Position the cut on the left */
  transform: rotate(-45deg);
  /* Rotate the cut */
}

.boxWithCuttingEffect::after {
  right: -23px;
  /* Position the cut on the right */
  transform: rotate(45deg);
  /* Rotate the cut */
}

.BillingCardBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.teamPaymentTableD th {
  background-color: none !important;
  color: black;
  border-bottom: 1px solid #e0dddd !important;

  padding: 7px;
  border-radius: 0px !important;
}

.TeamRenumCell {
  background-color: white !important;
  padding-top: 10px !important;
}

.RemInput input {
  height: 10px;
}

.clearIndicatorStyles {
  padding: 0px !important;
  /* Adjust this value as needed */
}

.billingtextBlack {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.ManageBoxSetting {
  min-width: 7rem;
  width: fit-content;
}

.subBtn {
  font-weight: 400 !important;
  font-size: 15px !important;
  padding: 8px 16px !important;
}

.displayCenter {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.couponCodeText input {
  color: #fff !important;
}

.locationBlueBtn {
  margin: 0 0 10px;
}

.locationBlueBtn button {
  width: 70%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  /* font-size: 1.1rem; */
}

.locationBlueBtn button {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  /* font-size: 1.1rem; */
}

.iconLeft {
  padding-left: 0px !important;
}

.subscriptionList tr {
  border-bottom: 1px solid #ccc !important;
}

.subscriptionList td {
  font-weight: 700 !important;
  font-size: 15px !important;
}

.subscriptionList tr:last-child {
  border-bottom: none !important;
}

.textfieldheight input {
  height: 10px;
}

.dashboardShareCopy {
  align-items: center !important;

  border: 2px solid #ccc;
  border-radius: 8px;
}

.couponCuttingEffect {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.couponCuttingEffect::before,
.couponCuttingEffect::after {
  content: "";
  position: absolute;
  top: -8px;
  bottom: -9px;
  width: 16px;
  height: 16px;
  background-color: #ebebeb;
  border-radius: 50%;
}

.couponCuttingEffect::before {
  left: -31px;
  /* Position the cut on the left */
  transform: rotate(-45deg);
  /* Rotate the cut */
}

.couponCuttingEffect::after {
  right: -31px;
  /* Position the cut on the right */
  transform: rotate(45deg);
  /* Rotate the cut */
}

.couponValidity b {
  font-weight: 400 !important;
}

.jobPreviewBoxScroll {
  max-height: 7rem;
  overflow-y: auto;
}

.contactTable td {
  font-weight: 600 !important;
  font-size: 15px !important;
}

.contactTable .MuiTableCell-root {
  box-shadow: none !important;
}

.contactTable {
  border-radius: 0px !important;
}

/* 17-01-2024 Start*/
.UserPortfolioAvatar img {
  /* object-fit: scale-down !important; */
  object-fit: fill !important;
}

.Videosliderimg img {
  height: 5rem !important;
  /* width: 5rem !important; */
  object-fit: cover;
}

.VideoPlayBtn {
  position: absolute;
  top: 27%;
  left: 39%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.VideoprojImgs {
  height: 12rem !important;
  object-fit: cover;
}

.Audiosliderimg img {
  height: 5rem !important;
  /* width: 5rem !important; */
  object-fit: cover;
}

.AudiosPlayBtn {
  position: absolute;
  top: 27%;
  left: 39%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.AudiosTalentliderimg img {
  height: 5rem !important;
  /* width: 5rem !important; */
  object-fit: cover;
}

.AudiosWebsiteLineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ProjectAudioPlayer {
  display: flex;
  height: 15rem;
  align-items: center;
  justify-content: center;
}

/* 17-01-2024 End*/

.contactTable th {
  background-color: #eceef6 !important;
  color: #000;
  padding: 7px;
  border-radius: 0px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 2.5rem !important;
}

.profileImgUpload:hover {
  background-image: url("../src/images/CompanyLogo.png");
  color: transparent !important;
  background-position: center;
}

.profileImgUpload {
  background-color: grey !important;
  opacity: 0.7;
  color: #fff !important;
}

.ProjectsSliders .slick-prev {
  left: -7px !important;
  cursor: pointer;
}

.ProjectsSliders .slick-next {
  right: 0px !important;
}

.SubscriptionShadow {
  box-shadow: 0 0 15px #d5d5d5 !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingPageTabIndicator .MuiTabs-indicator {
  /* background-color: none !important; */
  display: none !important;
}

.activitySearchIcon {
  background: #d3e1ea;
  border-radius: 10px !important;
  border: 0.5px solid rgba(145, 158, 171, 0.5) !important;
}

.activityCircleIcon {
  color: #001892;
  background: #001892;
  border-radius: 50%;
  box-shadow: 0px 0px 6px black;
  font-size: 7px !important;
}

.inboxTabs .MuiTabs-root {
  color: #001892 !important;
  border-radius: 10px;
  border: 1px solid #42ab5b !important;
  min-height: 40px !important;
}

.inboxTabs .MuiTabs-indicator {
  background-color: transparent !important;
}

.inboxTabs .MuiTabPanel-root {
  min-height: 25rem !important;
  margin-top: 1rem;
}

.inboxTabs .MuiTabs-flexContainer {
  /* justify-content: space-between !important;
  padding: 0px 8px !important; */
  border: 1px solid #001892;
  width: fit-content;
  border-radius: 8px;
  padding: 1px;
}

.inboxTabs .MuiButtonBase-root {
  margin: 0px !important;
  font-weight: 500;
  font-size: 16px;
  color: #001892;
  min-height: 38px !important;
  /* padding-left: 13px;
  padding-right: 13px; */
  padding: 11px 20px;
}

.inboxTabs .Mui-selected {
  color: #fff !important;
  background-color: #001892;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 5px !important;
  font-weight: 400;
  /* margin: 6px 0px !important; */
}

.inboxEquiryCard {
  border: 1px solid #d3e1ea;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  margin-bottom: 12px;
  box-shadow: none !important;
}

.inboxLeftSideBox {
  border: 0.1px solid #d3e1ea;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.TableLine td {
  border-left: none !important;
}

.institutionTable .MuiTableCell-root {
  box-shadow: none !important;
}

.CourseLink a {
  text-decoration: none !important;
}

/* @media (min-width: 992px) and (max-width: 1200px) { */
@media (max-width: 1500px) {
  .institutionTable .MuiTableCell-root {
    font-size: 13px;
  }
}

.inboxLeftGrid {
  padding-right: 8px;
  border-right: 1px solid #d3e1ea;
}

.inboxCountBox {
  /* background: green;
  padding: 6px;
  border-radius: 50%;
  margin-right: 8px; */
  background: green;
  padding: 6px;
  border-radius: 50%;
  margin-right: 8px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.inboxCountBox p {
  line-height: 10px;
  color: #fff;
}

.portfolioImgSilder {
  height: 7rem;
  object-fit: cover;
  object-position: top;
}

.tableJobPost td::first-letter {
  text-transform: uppercase;
}

.invoiceDetailsTable td {
  font-weight: 700 !important;
}

.walletPayoutTable th {
  background-color: #f2f2f2 !important;
  font-weight: 400;
  padding: 7px 16px;
  border-radius: 0px !important;
  font-size: 15px !important;
  line-height: 1.8rem !important;
}

.walletPayoutTable td {
  font-size: 15px !important;
  font-weight: 400;
}

.walletPayoutTable tr {
  border-bottom: 1px solid #ccc !important;
}

.walletPayoutTable tr:last-child {
  border-bottom: none !important;
}

.walletPayoutDetailTable tr:last-child {
  border-bottom: 1px solid #ccc !important;
}

.walletPayoutTable .MuiTableCell-root {
  box-shadow: none !important;
}

.selectfieldheight .MuiInputBase-root {
  height: 45px;
}

.selectfieldheight .MuiFormLabel-root {
  top: -4px !important;
}

.payoutTransferIcon {
  border: 1px solid #ccc !important;
  padding: 5px !important;
  border-radius: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

.addMoneyPaper {
  border: 1px solid #ccc;
  padding: 5px 18px;
  border-radius: 1rem !important;
  background-color: #f0f5f8 !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .addMoneyPaper {
    padding: 5px 12px;
  }
}

.addMoneyBtn {
  font-weight: 400 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  padding: 8px 24px !important;
}

.activityInfoTable th {
  background-color: #a6add3 !important;
  color: #030f54;
  font-weight: 400;
  padding: 7px 16px;
  border-radius: 0px !important;
  font-size: 15px !important;
  line-height: 1.8rem !important;
}

.activityInfoTable td {
  font-size: 15px !important;
  font-weight: 400;
}

.activityInfoTable thead tr th {
  border: 1px solid #ccc !important;
}

.activityInfoTable tbody tr td {
  border: 1px solid #ccc !important;
}

.activityInfoTable .MuiTableCell-root {
  box-shadow: none !important;
}

.activityInfoTableCellHeight {
  max-height: 4rem !important;
  overflow: auto;
}

.Tablepayment th {
  padding: 0px 0px 0px 0px !important;
}

.tablepaymentrow {
  padding-bottom: 11rem !important;
}

.noCouponsAvailable {
  width: 100%;
  padding: 1rem;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calenderArrowDate {
  border: 1px solid #000080;
  padding: 5px;
  border-radius: 5px;
}

.shootingSecBox {
  border: 2px solid #ccc;
  padding: 8px 14px;
  border-radius: 10px;
  /* min-height: 10rem; */
}

.calenderEditButton {
  position: absolute;
  bottom: 5%;
  left: 22%;
}

.calenderEditButton2 {
  position: absolute;
  bottom: 5%;
  left: 29%;
}

.ProjectIndex .MuiInputLabel-root {
  z-index: 0 !important;
}

.CalenderProjectSelect .css-1fdsijx-ValueContainer {
  height: 60px !important;
  border-radius: 8px !important;
}

.CalenderProjectSelect .css-hlgwow {
  height: 60px !important;
  border-radius: 8px !important;
}

.activityInfoTableCellHeight {
  max-height: 4rem !important;
  overflow: auto;
  word-break: break-all;
  padding-right: 5px;
}

.jobSeekSearchBoxMain {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  margin-top: 1rem;
  max-height: 26rem;
  overflow-y: auto;
}

.jobSeekSearchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 8px;
}

.jobSeekSearchImg {
  height: 4rem;
  width: 4rem;
}

.timelineLeftCard {
  min-height: 24rem;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  text-align: center;
  background: #ffffff;
}

.timelineRightCard {
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 23.7rem !important;
}

.timelineLeftBox {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #d3e1ea;
}

a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"]
  .fc-event-main-frame {
  display: block !important;
}

a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"]
  .fc-event-title {
  width: 95% !important;
  white-space: nowrap !important;
}

@media (max-width: 992px) {
  a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"]
    .fc-event-title {
    white-space: normal !important;
  }
}

a.fc-daygrid-event[style="background-color: blue;"] .fc-daygrid-block-event {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.fc .fc-daygrid-event {
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-top: 4px !important;
}

a.fc-daygrid-event[style="background-color: blue;"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 111;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.fc-daygrid-event[style="background-color: rgb(27, 170, 90);"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 111;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-daygrid-event-harness {
  /* position: absolute !important; */
  /* position: relative; */
  top: 0px;
  left: 0px;
  margin-top: 0 !important;
  visibility: visible !important;
  cursor: pointer;
}

.fc-popover-body .fc-h-event .fc-event-title {
  display: inline-block !important;
  white-space: wrap !important;
}

.fc-popover-body
  a.fc-daygrid-event[style="background-color: rgb(247, 159, 25);"] {
  height: 3.5rem;
  border-radius: 8px !important;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

.fc-popover-body
  a.fc-daygrid-event[style="background-color: rgb(27, 170, 90);"] {
  height: 3.5rem;
  border-radius: 8px !important;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

.fc-popover-body a.fc-daygrid-event[style="background-color: blue;"] {
  height: 3.5rem;
  border-radius: 8px;
  z-index: 222;
  overflow: hidden;
  color: whitesmoke;
  padding-top: 5px;
}

.fc-popover .fc-more-popover {
  width: 15rem !important;
}

.fc .fc-daygrid-more-link {
  z-index: 22222 !important;
}

.fc .fc-daygrid-event-harness-abs {
  position: absolute !important;
}

/* @media (max-width: 600px) {
  .fc-event-title .fc-sticky {
    display: none;
  }

  .fc-h-event .fc-event-title {
    display: none !important;
  }
} */

/* ------------------------------------------------------------------------------------------------ */

/* .fc .fc-popover-header {
  margin-bottom: 30px;
}

.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  border-top: none;
  padding-top: 50px;
} */

.redHelper .MuiFormHelperText-root {
  color: red !important;
}

.ReportLink a {
  text-decoration: none !important;
}

.jobCanActivetab {
  border-bottom: 2px solid #001892 !important;
}

.ProjectsActivetab {
  border-bottom: 3px solid #001892 !important;
}

.fc .fc-event .fc-event-main {
  width: -webkit-fill-available !important;
}

/* For Job Report Print Start */

@media print {
  .customTooltip .MuiTooltip-tooltip {
    display: none !important;
    /* Hide tooltip when printing */
  }
}

.institutionTable .MuiTableCell-root:first-of-type {
  padding-left: 16px !important;
}

/* For Job Report Print End */

.LanguageTable {
  table-layout: fixed;
}

.LanguageTable th {
  width: 300px;
  background-color: transparent !important;
}

.LanguageTable tr {
  border-bottom: 1px solid #ccc !important;
}

.LanguageTable td {
  color: #000 !important;
  font-weight: 600;
}

.LanguageTable th {
  color: #000 !important;
  font-weight: 600;
}

.LanguageTable .MuiTableCell-root {
  box-shadow: none !important;
}

.LanguageTableCont {
  border: 1px solid #ccc;
  border-radius: 8px;
}

.noEntryProjectIcon {
  width: fit-content;
  position: absolute;
  top: 3%;
  left: 2%;
  z-index: 2;
}

.projectNavTabs .MuiTabs-flexContainer {
  justify-content: end;
}

@media (max-width: 1400px) {
  .projectNavTabs .MuiTabs-flexContainer {
    justify-content: start;
  }
}

.ProjectDashTime {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.highlighted {
  background-color: red;
  /* Set the background color for highlighted dates */
}

/* --------------gallery-------- */

.album-contain {
  width: fit-content;
  cursor: pointer;
}

.album .centeredText {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
  margin: auto;
  height: 60px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  line-height: 1;
}

.photoAlbumTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.photoAlbumBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.albumIcon .photoAlbumBottomIcon {
  display: inline;
}

.PhotoAlbumEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.centeredText {
  text-align: center;
  top: 60%;
  position: absolute;
  left: 8%;
}

/* -------new------ */
.galleryAlbumContaniner {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.photoTopIcon {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  display: none;
}

.photoBottomIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.default:hover .photoTopIcon {
  display: block;
}

.horizontal:hover .photoTopIcon {
  display: block;
}

.vertical:hover .photoTopIcon {
  display: block;
}

.default:hover .photoBottomIcon {
  display: block;
}

.horizontal:hover .photoBottomIcon {
  display: block;
}

.vertical:hover .photoBottomIcon {
  display: block;
}

.VideoEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.photoEditIcon {
  color: "#fff";
  height: 25px;
  width: 25px;
}

.photoTopIcon.hide,
.videoHover.hide {
  display: none;
}

.photoTopIcon.show {
  display: block;
}

.videoHover.show {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.photoTopIcon.show .VideoEditIcon,
.videoHover.show .VideoEditIcon {
  display: none;
}

a:hover > .photoTopIcon.hide .VideoEditIcon,
a:hover > .videoHover.hide .VideoEditIcon {
  display: block !important;
}

.galleryAddMoreBtn {
  font-size: 15px !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #000 !important;
  padding-bottom: 0px !important;
  border-radius: 0px !important;
  color: #000 !important;
}

.galleryAddMoreBtn .MuiButton-startIcon {
  margin-right: 3px !important;
}

/* --------------GalleryEnd----------------- */

/* claender--- */
.react-calendar .highlight {
  /* background: #000080; */
  /* opacity: 0.3; */
  z-index: 0;
  background: #006edc;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
  /* opacity: 1 !important; */
}

.ShowDate .react-calendar__tile--now {
  /* background: #000080; */
  background: #006edc;
}

.react-calendar__tile--now {
  background: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #000 !important;
}

.react-calendar * {
  font-family: "Poppins", sans-serif !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar abbr {
  position: relative;
  z-index: 123;
}

.react-calendar {
  border: none !important;
  width: 100% !important;
}

.react-calendar button {
  position: relative;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 16px;
  font-weight: 600;
}

.react-calendar__navigation__arrow {
  font-size: 25px !important;
}

.react-calendar__tile--active {
  background: #006edc !important;
  /* background: #000080 !important;
  opacity: 0.3; */
  color: #000 !important;
}

.unpubBtn {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.viewCandidateImg {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.EventTimeline .rct-item {
  width: auto !important;
}

.SubscriptionTeamdashscroll::-webkit-scrollbar {
  width: 5px;
}

.SubscriptionTeamdashscroll::-webkit-scrollbar-thumb {
  background-color: #d9dde4;
  height: 10px;
}

.SubscriptionTeamdashscroll::-webkit-scrollbar-track {
  background-color: #44577a;
}

.selectedThemeProfile {
  box-shadow: 0 0 10px #000080 !important;
  border: 2px solid #000080;
}

.flexAlign {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  z-index: 22;
  background: #fff;
  box-shadow: 2px 2px 0px 0px rgba(145, 158, 171, 0.2);
}


.navbar-nav .nav-item {
    position: relative;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  left: 15%;
  right: 40%;
  bottom: -3px;
  height: 2px;
  background: #000080;
}


.nav-link.active {
  color: #000080 !important;
  font-weight: 600;
}

.locationAboutus .active {
  color: #000 !important;
  font-weight: normal;
}

.locationAboutus .active::after {
  background-color: transparent !important;
}

.locationoutlines {
    border-radius: 10px !important;
    background-color: #fff !important;
}

.Enquirycolor .MuiOutlinedInput-input {
    color: grey !important;
}

.footerFollowBtn{
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #fff !important ;
  border-radius: 5px !important;
  padding: 0px 10px !important;
  font-weight: lighter !important;
}


.news_lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}



/* App.css */
.custom-select__menu {
  z-index: 9999 !important; 
  max-height: 15rem !important;
}

.custom-select__menu-list {
  z-index: 1 !important;  
  max-height: 15rem !important;
   cursor: pointer !important;

}
.custom-select__control{
  height: 53px;
    border-radius: 8px !important;
    cursor: pointer !important;
}

.custom-select__indicator-separator{
  display: none;
}

.custom-select__option--is-selected {
  background-color: #fff !important;
  color:  #000 !important;
  cursor: pointer !important;
}


.custom-select__option--is-selected:hover{
  background-color: #B2D4FF !important;
}


.WebsiteThemeCard{
  height: 25rem;
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important; 
  border-bottom-right-radius: 0px !important;
  cursor: pointer;
}

.WebsiteThemeTypeBox{
 padding: 5px;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-bottom-left-radius: 8px ; 
  border-bottom-right-radius: 8px ;
}


.inboxRightBox {
  max-height: 50rem;
  overflow-y: auto;
  padding-right: 5px;
}

.custom-select-role__option{
  text-transform: capitalize;
}

.custom-select-role__indicator-separator {
  display: none;
}


.custom-select-role__option--is-selected {
  background-color: #fff !important;
  color: #000 !important;
  cursor: pointer !important;
}


.custom-select-role__option--is-selected:hover {
  background-color: #B2D4FF !important;
}


.custom-select-role__menu-list::-webkit-scrollbar {
  width: 5px;
}

.custom-select-role__menu-list::-webkit-scrollbar-thumb {
  background-color: #44577a;
  /* background-color: #d9dde4; */
  height: 10px;
}

.custom-select-role__menu-list::-webkit-scrollbar-track {
  background-color: #d9dde4;
  /* background-color: #44577a; */
}